import { PropsWithChildren } from 'react';

import { InputWrapperProps } from '../types';

import {
  DefaultInputWrapper,
  RemoveButton,
  Required,
  DefaultInputLabel,
} from './styled';

import Icon from '@design0.2/Icon/Icon';
import { styled } from '@styles/stitches.config';

function InputWrapper({
  small,
  label,
  caption,
  errorMessage,
  unit,
  suffix,
  hiddenClear,
  leftIcon,
  rightIcon,
  actionButton,
  showError,
  children,
  onRemove,
  prefixValue,
  value,
  disabled,
  required,
}: PropsWithChildren<InputWrapperProps>) {
  const hasCaption = caption || errorMessage;
  const hasActionButton = !!actionButton;
  const removable = !hiddenClear && !!value && !disabled;

  return (
    <div>
      <Container>
        <DefaultInputLabel
          small={small}
          showError={showError}
          disabled={disabled}
          hasActionButton={hasActionButton}
        >
          {leftIcon && <BlockInInput>{leftIcon}</BlockInInput>}
          <Wrapper hasContent={!!value}>
            {label && (
              <span>
                {label}
                {required && <Required>{'*'}</Required>}
              </span>
            )}
            <Prefix>
              {prefixValue && <span>{prefixValue}</span>}
              {children}
            </Prefix>
          </Wrapper>
          {removable && (
            <RemoveButton
              type={'button'}
              onClick={(e) => {
                e.stopPropagation(); // label로 이벤트 전파를 막는다.
                e.preventDefault(); // 클릭 후 focus를 푼다.

                onRemove?.(e);
              }}
            >
              <Icon type={'delete | black'} />
            </RemoveButton>
          )}
          {rightIcon && <BlockInInput>{rightIcon}</BlockInInput>}
          {!!suffix && (
            <BlockInInput>
              <Counter>{suffix}</Counter>
            </BlockInInput>
          )}
          {!!unit && <BlockInInput>{unit}</BlockInInput>}
          {hasActionButton && <BlockInInput>{actionButton}</BlockInInput>}
        </DefaultInputLabel>
      </Container>
      {hasCaption && (
        <Descriptions>
          <Caption>{caption}</Caption>
          {typeof errorMessage === 'string' ? (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          ) : (
            errorMessage
          )}
        </Descriptions>
      )}
    </div>
  );
}

const Container = styled('div', {
  fontType: 'subtitleRegular',
  color: '$grey100',
});

const Wrapper = styled(DefaultInputWrapper, {
  flex: 1,
});

const Counter = styled('span', {
  color: '$black',
  fontType: 'bodyRegular',
});

const BlockInInput = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Descriptions = styled('div', {
  marginTop: 8,
  fontType: 'captionRegular',
  color: '$grey100',
});

const Caption = styled('p', {
  whiteSpace: 'pre-line',
});

const ErrorMessage = styled('p', {
  color: '$red100',
});

const Prefix = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  span: {
    fontType: 'subtitleRegular',
    whiteSpace: 'pre', // 띄어쓰기가 필요한 경우가 있음.
  },
});

export default InputWrapper;
