import { flatMapDeep } from 'lodash';

import { EOnboardingSteps } from '@user/types';

type Obj = {
  [key in string]: string | Obj;
};

export const flatMapDeepToString = (obj: Obj): string[] => {
  return flatMapDeep(obj, (v) =>
    typeof v === 'string' ? v : flatMapDeepToString(v)
  );
};

const getOnboardingProcess = (isSeller: boolean): EOnboardingSteps[] => {
  if (isSeller) {
    return [
      'linkName',
      'kemiUsageType',
      'productToSellType',
      'snsUsageType',
      'result',
    ];
  }

  return ['linkName', 'kemiUsageType', 'snsUsageType', 'result'];
};

/**
 * @param isSeller
 * @param current
 * @description 온보딩 진행 순서 <br>
 * linkname(isOldOnboardedUser: true일 경우) <br>
 * -> kemiUsageType <br>
 * -> productToSellType(kemiUsageType === 'SELLER'일 경우) <br>
 * -> snsUsageType <br>
 * -> result <br>
 */
export const getNextOnboardingStep = (
  isSeller: boolean,
  current: EOnboardingSteps
) => {
  const process = getOnboardingProcess(isSeller);
  const index = process.indexOf(current);

  return process[index + 1];
};
