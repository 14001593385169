import { useQuery } from '@tanstack/react-query';

import { useMeForStoreQuery } from './graphql/userStore.generated';

import { randomCoverImage, randomProfileImage } from '@global/helper/image';
import * as Types from 'schema/types';
import { ELoginType } from 'schema/types';
import { getAccessToken } from 'src/auth/modules/kemiToken';

export type User = {
  userId: string;
  kemiId: string;
  title: string;
  linkName: string;
  loginType: ELoginType;
  profile: {
    name: string;
    email: string;
    phoneNumber: string;
    address: string;
    addressDetail: string;
    addressZipCode: string;
    coverImageUrl: string;
    profileImageUrl: string;
    allowMarketingNotification?: boolean;
    gender: string;
    birth: string;
  };
  sns: { type: Types.ESnsType; link: string }[];
  createdAt: string;
  sellerInfo: {
    sns?: { type?: Types.ESellerSnsType | null; url?: string | null } | null;
    grade?: number | null;
    isSeller: boolean;
    isInstagramSeller: boolean;
  };
  userType: {
    kemiUsageType?: Types.EKemiUsageType | null;
    productToSellType?: Array<Types.EProductToSellType> | null;
    snsUsageType?: Types.ESnsUsageType | null;
  };
};

/**
 * me query의 정규화 버전
 *
 * data가 null인 것은 비로그인 상태를 의미한다.
 */
const useUserQuery = () =>
  useQuery<User | null>(['user'], async () => {
    try {
      const accessToken = await getAccessToken();

      if (accessToken === null) {
        return null;
      }

      const response = await useMeForStoreQuery.fetcher()();

      if (!response.me) {
        return null;
      }

      let sns = response.me.kemi.sns ?? [];

      if (sns.length === 0) {
        sns = [
          { type: 'EMAIL', link: '' },
          { type: 'INSTAGRAM', link: '' },
          { type: 'YOUTUBE', link: '' },
          { type: 'NAVERBLOG', link: '' },
          { type: 'FACEBOOK', link: '' },
          { type: 'TWITTER', link: '' },
          { type: 'TIKTOK', link: '' },
        ];
      }

      const user = {
        userId: response.me.id,
        kemiId: response.me.kemi.id,
        title: response.me.kemi.title,
        linkName: response.me.linkName,
        loginType: response.me.loginType || 'ETC',
        sns: sns,
        createdAt: response.me.createdAt,
        profile: {
          name: response.me.userProfile?.name || '',
          email: response.me.userProfile?.email || '',
          phoneNumber: response.me.userProfile?.phoneNumber || '',
          address: response.me.userProfile?.address?.default || '',
          addressDetail: response.me.userProfile?.address?.detailed || '',
          addressZipCode: response.me.userProfile?.address?.zipCode || '',
          coverImageUrl: response.me.kemi.coverImageUrl || randomCoverImage(),
          profileImageUrl:
            response.me.kemi.profileImageUrl || randomProfileImage(),
          allowMarketingNotification:
            response.me.userProfile?.allowMarketingNotification,
          gender: response.me.userProfile?.gender || '',
          birth: response.me.userProfile?.birth || '',
        },
        sellerInfo: {
          sns: response.me.sellerInfo?.sns,
          grade: response.me.sellerInfo?.grade,
          isSeller: !!response.me.sellerInfo?.sns?.type,
          isInstagramSeller: response.me.sellerInfo?.sns?.type === 'INSTAGRAM',
        },
        userType: {
          kemiUsageType: response.me.userType?.kemiUsageType,
          productToSellType: response.me.userType?.productToSellType,
          snsUsageType: response.me.userType?.snsUsageType,
        },
      };

      return user;
    } catch (e) {
      return null;
    }
  });

export default useUserQuery;
