import * as Dialog from '@radix-ui/react-dialog';
import React, { PropsWithChildren } from 'react';

import FloatingButton from '@design0.2/Button/FloatingButton';
import Text from '@global/components/atoms/Text';
import i18n from '@i18n/index';
import useUserAgent from '@shared/hooks/useUserAgent';
import Close from '@static/svg/Close';
import { styled } from '@styles/stitches.config';

type FullModalProps = PropsWithChildren<{
  title?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  disableOutsidePointerEvents?: boolean;
  withoutHeader?: boolean;
}>;

const FullModal = ({
  title,
  onClose,
  onSubmit,
  disableOutsidePointerEvents,
  withoutHeader,
  children,
}: FullModalProps) => {
  const { isDesktop } = useUserAgent();

  return (
    <Dialog.Root
      defaultOpen={true}
      onOpenChange={(open) => !open && onClose?.()}
    >
      <Dialog.Portal>
        <DialogOverlay className={'DialogOverlay'}>
          <DialogContent
            className={'DialogContent'}
            isDesktop={isDesktop}
            // radix에서 해주는 focus로 인한 outline 효과를 없애기 위한 로직
            onOpenAutoFocus={(e) => e.preventDefault()}
            onPointerDownOutside={(e) => {
              if (disableOutsidePointerEvents) {
                e.preventDefault();
              }
            }}
          >
            <Container>
              {!withoutHeader && (
                <FixedHeader isDesktop={isDesktop}>
                  <Header>
                    <Text font={'titleBold'}>{title}</Text>
                    <Close onClick={onClose} />
                  </Header>
                </FixedHeader>
              )}
              {children}
            </Container>
            <FloatContainer hidden={!onSubmit}>
              <FloatingButton onClick={onSubmit}>
                {i18n.t('k_save_and_close')}
              </FloatingButton>
            </FloatContainer>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const DialogOverlay = styled(Dialog.Overlay, {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: '$fullModal',
  transparentColor: 'translucentBlack',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
});

const DialogContent = styled(Dialog.Content, {
  position: 'relative',
  height: '100%',
  width: '100%',
  variants: {
    isDesktop: {
      true: {
        paddingY: 16,
        width: 480,
        borderRadius: 16,
      },
    },
  },
});

const FixedHeader = styled('div', {
  width: 'inherit',
  position: 'sticky',
  top: 0,
  background: '$white',
  zIndex: '$fullModal',
  variants: {
    isDesktop: {
      true: {
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
      },
    },
  },
});

const Header = styled('div', {
  height: 64,
  padding: 16,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Container = styled('div', {
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

const FloatContainer = styled('div', {
  position: 'absolute',
  width: '100%',
  left: 0,
  bottom: 24,
  paddingBottom: 16,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 'calc(var($zIndices$fullModal) + 1)',
  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export default FullModal;
