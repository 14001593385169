export const MIGRATION_ERROR = {
  NO_PAGE: 'NO_PAGE',
  NO_CARD: 'NO_CARD',
};

export const MAX_IMAGE_SIZE_MEGA_BYTE = 20;
export const MAX_IMAGE_SIZE_BYTE = MAX_IMAGE_SIZE_MEGA_BYTE * 1024 * 1024;

export const IMAGE_SIZE = {
  PROFILE: 128,
};

export const MAX_INPUT_LENGTH = {
  LINKNAME: 20,
};

export const KEMI_TOKEN_EXPIRES_TIME = 3600;

export const KEMI_THEME_ROOT_ID = 'theme-root';
export const KEMI_ROOT_TAG_ID = 'kemi-root';
export const KEMI_MODAL_ROOT_TAG_ID = 'kemi-modal-root';
export const KEMI_MOBILE_SIDEMENU_ROOT_TAG_ID = 'kemi-mobile-sidemenu-root';

export const ACCEPTABLE_IMAGE_TYPE = ['jpg', 'jpeg', 'png', 'gif', 'heic'];

export const QUERY = {
  BACK_URL: 'back-url',
  SIGNUP_MODAL: 'signup-modal',
  IMAGE_UPLOAD_MODAL: 'image-upload-modal',
  REQUEST_SAMPLE_MODAL: 'request-sample-modal',
  BUY_NOW_MODAL: 'buy-now-modal',
  SELECT_MAIN_SNS_MODAL: 'select-main-sns-modal',
  PRICE_DETAIL_MODAL: 'price-detail-modal',
  POST_CODE_MODAL: 'post-code-modal',
  PRODUCT_OPTION_MODAL: 'product-option-modal',
  OPEN_KEMI: 'open-kemi',
};

export const SNS_PREFIX = {
  EMAIL: '',
  INSTAGRAM: '@',
  YOUTUBE: '',
  NAVERBLOG: '',
  FACEBOOK: '',
  TWITTER: '@',
  TIKTOK: '@',
  ETC: '',
};

export const COMMON_MESSAGE = {
  NO_ERROR: undefined,
};

export const EVENT_TAG = {
  LANDING: {
    SCREEN_OPEN_LANDING: 'screen_open_landing',
    LANDING_CLICK_LOGIN: 'landing_click_signin',
    LANDING_CLICK_SIGNUP: 'landing_click_signup',
    LANDING_CLICK_KEMI_URL_INPUT: 'landing_click_kemi_url_input',
    LANDING_CLICK_KEMI_START: 'landing_click_kemi_start',
    LANDING_CHANGE_LINKNAME: 'landing_change_linkname',
    LD_HOME_SCREEN_OPEN: 'ldHomeScreenOpen',
    LD_HOME_SIGNIN_CLICK: 'ldHomeSigninClick',
    LD_HOME_SIGNUP_CLICK: 'ldHomeSignupClick',
    LD_HOME_KEMI_URI_INPUT_CLICK: 'ldHomeKemiUriInputClick',
    LD_HOME_KEMISTART_CLICK: 'ldHomeKemistartClick',
    LD_HOME_FAQ_CLICK: 'ldHomeFaqClick',
    LD_HOME_CONTACT_CLICK: 'ldHomeContactClick',
    LD_SELLER_SCREEN_OPEN: 'ldSellerScreenOpen',
    LD_SELLER_FREESTART_CLICK: 'ldSellerFreestartClick',
    SCREEN_OPEN_TYPE_SELECTION: 'screenOpenTypeSelection',
    LD_TYPE_SELECTION_NEXT_CLICK: 'ldTypeSelectionNextClick',
    LD_TYPE_SELECT_LATER_CLICK: 'ldTypeSelectLaterClick',
    SCREEN_OPEN_TERMS_SELECTION: 'screenOpenTermsSelection',
    LD_TERMS_CLICK: 'ldTermsClick',
    LD_TERMS_AGREE_CLICK: 'ldTermsAgreeClick',
    LD_TERMS_LATER_CLICK: 'ldTermsLaterClick',
  },
  LANDING_TOAST: {
    GOOGLE_SIGNIN_TOAST: 'google_signin_toast',
    GOOGLE_SIGNIN_TOAST_CLICK_CLIPBOARD: 'google_signin_toast_click_clipboard',
    GOOGLE_SIGNIN_TOAST_CLICK_CLOSE: 'google_signin_toast_click_close',
  },
  REGISTER: {
    SCREEN_OPEN_SIGNUP_POPUP: 'screen_open_signup_popup',
    SIGNUP_CLICK_CLOSE: 'signup_click_close',
    SIGNUP_CLICK_SIGNUP_START: 'signup_click_signup_start',
    SIGNUP_CLICK_GO_TO_SIGNIN: 'signup_click_go_to_signin',
    SIGNUP_DONE: 'signup_done',
  },
  LOGIN: {
    SCREEN_OPEN_SIGNIN_POPUP: 'screen_open_signin_popup',
    SIGNIN_CLICK_CLOSE: 'signin_click_close',
    SIGNIN_CLICK_SIGNIN_START: 'signin_click_signin_start',
    SIGNIN_CLICK_GO_TO_SIGNUP: 'signin_click_go_to_signup',
    KEMI_ID_INPUT_FOCUS_OUT: 'kemiIdInputFocusOut',
    KEMI_ID_LOGIN_CLICK: 'kemiIdLoginClick',
  },
  SIGN_IN_AND_UP: {
    SCREEN_OPEN_SIGNUP_POPUP: 'screenOpenSignupPopup',
    SIGNUP_POPUP_CLOSE_CLICK: 'signupPopupCloseClick',
    SCREEN_OPEN_SIGNIN_POPUP: 'screenOpenSigninPopup',
    SIGNUP_POPUP_SIGNIN_CLOSE_CLICK: 'signupPopupSigninCloseClick',
    SIGNUP_POPUP_PROVIDER_CLICK: 'signupPopupProviderClick',
    SIGNUP_POPUP_NONMEMBER_CLICK: 'signupPopupNonmemberClick',
    SCREEN_OPEN_TERMS_N_CONDITIONS_CHECK: 'screenOpenTermsNConditionsCheck',
    TERMS_N_CONDITIONS_CLOSE_CLICK: 'TermsNConditionsCloseClick',
    TERMS_N_CONDITIONS_CHECK_CLICK: 'TermsNConditionsCheckClick',
  },
  KEMI_PLAYGROUND_HOME: {
    SCREEN_OPEN_KPGHOME: 'screen_open_kpghome',
    KPGHOME_CLICK_LINK_COPY: 'kpghome_click_link_copy',
    KPGHOME_CLICK_PREVIEW: 'kpghome_click_preview',
    KPGHOME_CLICK_EDIT_PROFILE: 'kpghome_click_edit_profile',
    KPGHOME_CLICK_EDIT_SNS: 'kpghome_click_edit_sns',
    KPGHOME_CLICK_ADD_CARD: 'kpghome_click_add_card',
    KPGHOME_CLICK_ADD_PRODUCT_BANNER: 'kpghome_click_add_product_banner',
    KPGHOME_CLICK_EDIT_DESIGN: 'kpghome_click_edit_design',
    KPG_PROFILE_EDIT_CLICK: 'kpgProfileEditClick',
    KPG_CLICK_DELETE_MAIN_IMAGE: 'kpgClickDeleteMainImage',
    KPG_CLICK_DELETE_CANCEL_MAIN_IMAGE: 'kpgClickDeleteMainImageCancel',
    KPGHOME_CLICK_SHARE: 'kpghomeClickShare',
    KPGHOME_CLICK_QRCODE: 'kpghomeClickQrCode',
    KPGHOME_CLICK_SHARE_QR_CODE: 'kpghomeClickShareQrCode',
    KPGHOME_CLICK_LINK_CARD_TOOLTIP: 'kpghomeClickLinkCardTooltip',
  },
  KEMI_EDIT_MAIN_IMAGE: {
    SCREEN_OPEN_MAIN_IMAGE: 'screenOpenKpgMainImage',
    KPG_MAIN_IMAGE_SAVE_CLICK: 'kpgMainImagelClickSave',
    KPG_MAIN_IMAGE_SAVE_SUCCESS: 'kpgMainImageSaveSuccess',
    KPG_MAIN_IMAGE_SAVE_CANCEL_CLICK: 'kpgMainImageClickSaveCancel',
  },
  KEMI_EDIT_THEME: {
    SCREEN_OPEN_EDIT_DESIGN: 'screenOpenEditDesign',
  },
  KEMI_EDIT_PROFILE: {
    SCREEN_OPEN_KPGEDITPROFILE: 'screen_open_kpgeditprofile',
    SCREEN_OPEN_KPG_MY_KEMI: 'screenOpenKpgMyKemi',
    KPG_EDITPROFILE_CLICK_PREPAGE: 'kpg_editprofile_click_prepage',
    KPG_EDITPROFILE_CLICK_SAVE: 'kpg_editprofile_click_save',
    KPG_EDITPROFILE_CLICK_EDIT_COVERIMG: 'kpg_editprofile_click_edit_coverimg',
    KPG_EDITPROFILE_CLICK_EDIT_PROFILEIMG:
      'kpg_editprofile_click_edit_profileimg',
    KPG_EDITPROFILE_CLICK_NAME_INPUT: 'kpg_editprofile_click_name_input',
    KPG_EDITPROFILE_CLICK_URL_INPUT: 'kpg_editprofile_click_url_input',
    KPG_EDITPROFILE_CLICK_BIO_INPUT: 'kpg_editprofile_click_bio_input',
    KPG_PROFILE_EDIT_ACTION_CLICK: 'kpgProfileEditActionClick',
    KPG_MY_KEMI_PROFILE_CLICK_SAVE: 'kpgMyKemiProfileClickSave',
    KPG_MY_KEMI_PROFILE_SAVE_SUCCESS: 'kpgMyKemiProfileSaveSuccess',
    KPG_MY_KEMI_PROFILE_SAVE_FAIL: 'kpgMyKemiProfileSaveFail',
    KPG_MY_KEMI_PROFILE_CLICK_SAVE_CANCEL: 'kpgMyKemiProfileClickSaveCancel',
    KPG_MY_KEMI_BANNER_CLICK_SAVE: 'kpgMyKemiBannerClickSave',
    KPG_MY_KEMI_BANNER_SAVE_SUCCESS: 'kpgMyKemiBannerSaveSuccess',
    KPG_MY_KEMI_BANNER_SAVE_FAIL: 'kpgMyKemiBannerSaveFail',
    KPG_MY_KEMI_BANNER_CLICK_SAVE_CANCEL: 'kpgMyKemiBannerClickSaveCancel',
    KPG_MY_KEMI_CLICK_PROFILE: 'kpgMyKemiClickProfile',
    KPG_MY_KEMI_CLICK_BANNER: 'kpgMyKemiClickBanner',
  },
  KEMI_EDIT_SNS: {
    SCREEN_OPEN_KPGEDITSNS: 'screen_open_kpgeditsns',
    KPG_EDITSNS_CLICK_PREPAGE: 'kpg_editsns_click_prepage',
    KPG_EDITSNS_CLICK_SAVE: 'kpg_editsns_click_save',
    KPG_EDITSNS_CLICK_EMAIL_INPUT: 'kpg_editsns_click_email_input',
    KPG_EDITSNS_CLICK_INSTA_INPUT: 'kpg_editsns_click_insta_input',
    KPG_EDITSNS_CLICK_YOUTUBE_INPUT: 'kpg_editsns_click_youtube_input',
    KPG_EDITSNS_CLICK_NBLOG_INPUT: 'kpg_editsns_click_nblog_input',
    KPG_EDITSNS_CLICK_FBOOK_INPUT: 'kpg_editsns_click_fbook_input',
    KPG_EDITSNS_CLICK_TWITTER_INPUT: 'kpg_editsns_click_twitter_input',
    KPG_EDITSNS_CLICK_TIKTOK_INPUT: 'kpg_editsns_click_tiktok_input',
  },
  KEMI_EDIT_DESIGN: {
    KPG_EDITDEGIGN_CLICK_SAVE: 'kpg_editdesign_click_save',
    KPG_EDITDEGIGN_CLICK_PREPAGE: 'kpg_editdesign_click_prepage',
  },
  KEMI_ADD_CARD_TYPE: {
    SCREEN_OPEN_KPGADDCARDTYPE: 'screen_open_kpgaddcardtype',
    KPG_ADDCARDTYPE_CLICK_CLOSE: 'kpg_addcardtype_click_close',
    KPG_ADDCARDTYPE_CLICK_SELECT: 'kpg_addcardtype_click_select',
  },
  KEMI_ADDCARD_LINK: {
    SCREEN_OPEN_KPGADDCARDLINK: 'screen_open_kpgaddcardlink',
    SCREEN_OPEN_KPGADDCARDPRODUCT: 'screen_open_kpgaddcardproduct',
    SCREEN_OPEN_KPGADDTEXT: 'screen_open_kpgaddtext',
    KPG_ADDCARDLINK_CLICK_ADD: 'kpg_addcardlink_click_add',
    KPG_ADDCARDTEXT_CLICK_ADD: 'kpg_addcardtext_click_add',
    KPG_ADDCARDLINK_CLICK_URL_INPUT: 'kpg_addcardlink_click_url_input',
    KPG_ADDCARDLINK_CLICK_NAME_INPUT: 'kpg_addcardlink_click_name_input',
    KPG_ADDCARDLINK_CLICK_BADGE_TOGGLE: 'kpg_addcardlink_click_badge_toggle',
    KPG_ADDCARDLINK_CLICK_IMG_TOGGLE: 'kpg_addcardlink_click_img_toggle',
    KPG_ADDCARDLINK_CLICK_IMGTAB: 'kpg_addcardlink_click_imgtab',
    KPG_ADDCARDLINK_CLICK_IMGSIZE: 'kpg_addcardlink_click_imgsize',
    KPG_ADDCARDLINK_CLICK_CHANGEEMOJI: 'kpg_addcardlink_click_changeemoji',
    SCREEN_OPEN_KPG_ADD_CARD_LINK: 'screenOpenKpgAddCardLink',
    KPG_LINK_CARD_ADD_SUCCESS: 'kpgLinkCardAddSuccess',
    KPG_ADDCARDLINK_CLICK_SWITCH_PRODUCT_CARD:
      'kpg_addcardlink_click_switch_product_card',
  },
  KEMI_ADD_CARD_IMAGE: {
    KPG_IMAGE_CARD_ADD_SUCCESS: 'kpgImageCardAddSuccess',
  },
  KEMI_ADD_CARD_TEXT: {
    KPG_TEXT_CARD_ADD_SUCCESS: 'kpgTextCardAddSuccess',
  },
  KEMI_ADD_CARD_VIDEO: {
    SCREEN_OPEN_KPG_ADD_CARD_VIDEO: 'screenOpenKpgAddCardVideo',
    KPG_VIDEO_CARD_ADD_SUCCESS: 'kpgVideoCardAddSuccess',
  },
  KEMI_ADD_CARD_MUSIC: {
    SCREEN_OPEN_KPG_ADD_CARD_MUSIC: 'screenOpenKpgAddCardMusic',
    KPG_MUSIC_CARD_ADD_SUCCESS: 'kpgMusicCardAddSuccess',
  },
  KEMI_EDIT_CARD: {
    SCREEN_OPEN_KPG_EDITCARD: 'screen_open_kpg_editcard',
    KPG_EDITCARD_CLICK_CLOSE: 'kpg_editcard_click_close',
    KPG_EDITCARD_CLICK_EDIT: 'kpg_editcard_click_edit',
    KPG_EDITCARD_CLICK_DELETE: 'kpg_editcard_click_delete',
  },
  KPG_SELLER_REGISTER: {
    SCREEN_OPEN_KPG_SELLER_REGISTER: 'screen_open_kpg_seller_register',
    KPG_SELLER_REGISTER_CLICK_CLOSE: 'kpg_seller_register_click_close',
    KPG_SELLER_REGISTER_CLICK_CONFIRM: 'kpg_seller_register_click_confirm',
    SCREEN_OPEN_KPG_SELLER_REGISTER_SNS_TYPE_POPUP:
      'screen_open_kpg_seller_register_sns_type_popup',
    KPG_SELLER_REGISTER_SNS_TYPE_POPUP_CLICK_SNS_TYPE:
      'kpg_seller_register_sns_type_popup_click_sns_type',
    KPG_SELLER_REGISTER_SNS_TYPE_POPUP_CLICK_CLOSE:
      'kpg_seller_register_sns_type_popup_click_close',
    SCREEN_OPEN_KPG_SELLER_REGISTER_SNS_CONFIRM:
      'screen_open_kpg_seller_register_sns_confirm',
    KPG_SELLER_REGISTER_SNS_CONFIRM_CLICK_INSTA_BUSINESS_GUIDE:
      'kpg_seller_register_sns_confirm_clcik_insta_business_guide',
    KPG_SELLER_REGISTER_SNS_CONFIRM_CLICK_CONFIRM_FACEBOOK_ID:
      'kpg_seller_register_sns_confirm_clcik_confirm_facebook_id',
    KPG_SELLER_REGISTER_SNS_CONFIRM_CLICK_LATER:
      'kpg_seller_register_sns_confirm_clcik_later',
    KPG_SELLER_REGISTER_SNS_CONFIRM_CLICK_PREPAGE:
      'kpg_seller_register_sns_confirm_clcik_prepage',
    SCREEN_OPEN_KPG_SELLER_REGISTER_INSTA_BUSINESS_GUIDE:
      'screen_open_kpg_seller_register_insta_business_guide',
    KPG_SELLER_REGISTER_INSTA_BUSINESS_GUIDE_CLOSE:
      'kpg_seller_register_insta_business_guide_close',
    SCREEN_OPEN_KPG_SELLER_REGISTER_DONE:
      'screen_open_kpg_seller_register_done',
    KPG_SELLER_REGISTER_PERSONALINFO_FOCUS_OUT:
      'kpgSellerRegisterPersonalinfoFocusOut',
  },
  KEMI_SIDEMENU: {
    SCREEN_OPEN_KEMI_SIDEMENU: 'screen_open_kemi_sidemenu',
    KEMI_SIDEMENU_CLICK_CLOSE: 'kemi_sidemenu_click_close',
    KEMI_SIDEMENU_CLICK_SIGNIN: 'kemi_sidemenu_click_signin',
    KEMI_SIDEMENU_CLICK_SIGNUP: 'kemi_sidemenu_click_signup',
    KEMI_SIDEMENU_CLICK_MYKEMI: 'kemi_sidemenu_click_mykemi',
    KEMI_SIDEMENU_CLICK_CART: 'kemi_sidemenu_click_cart',
    KEMI_SIDEMENU_CLICK_ORDERLIST: 'kemi_sidemenu_click_orderlist',
    SCREEN_OPEN_KPG_SIDEMENU: 'screen_open_kpg_sidemenu',
    KPG_SIDEMENU_CLICK_CLOSE: 'kpg_sidemenu_click_close',
    KPG_SIDEMENU_CLICK_PERSONAL_INFO: 'kpg_sidemenu_click_personal_info',
    KPG_SIDEMENU_CLICK_KEMI_ANALYTICS: 'kpg_sidemenu_click_kemi_analytics',
    KPG_SIDEMENU_CLICK_SELLER_CENTER: 'kpg_sidemenu_click_sellercenter',
    KPG_SIDEMENU_CLICK_INSTA_ANALYTICS: 'kpg_sidemenu_click_insta_analytics',
    KPG_SIDEMENU_CLICK_SETTING: 'kpg_sidemenu_click_setting',
    KPG_SIDEMENU_CLICK_CHANNELTALK: 'kpg_sidemenu_click_channeltalk',
    KPG_SIDEMENU_CLICK_MY_COMMISSION: 'kpg_sidemenu_click_my_commission',
    KPG_SIDEMENU_CLICK_MY_COMMISSION_PROFIT:
      'kpg_sidemenu_click_my_commission_profit',
    SCREEN_OPEN_KPG_PERSONAL_INFO: 'screen_open_kpg_personal_info',
    KPG_PERSONAL_INFO_CLICK_SAVE: 'kpg_personal_info_click_save',
    KPG_PERSONAL_INFO_CLICK_PREPAGE: 'kpg_personal_info_click_prepage',
    SCREEN_OPEN_KPG_PERSONAL_INFO_GENDER_POPUP:
      'screen_open_kpg_personal_info_gender_popup',
    KPG_PERSONAL_INFO_GENDER_POPUP_CLICK_CLOSE:
      'kpg_personal_info_gender_popup_click_close',
    KPG_PERSONAL_INFO_GENDER_POPUP_CLICK_SELECT:
      'kpg_personal_info_gender_popup_click_select',
    KPG_PERSONAL_INFO_LOGOUT_CLICK: 'kemi_logout_click',
  },
  KPG_SIDEMENU: {
    KPG_SM_GENERAL_EDIT_DESIGN_CLICK: 'kpgSmGeneralEditDesignClick',
    KPG_SM_GENERAL_BLOCK_VISITOR_CLICK: 'kpgSmGeneralBlockVisitorClick',
    KPG_SM_GENERAL_ADDCARD_CLICK: 'kpgSmGeneralAddcardClick',
    KPG_SM_GENERAL_KEMIHOME_CLICK: 'kpgSmGeneralKemihomeClick',
    KPG_SM_GENERAL_EDIT_PROFILE_CLICK: 'kpgSmGeneralEditProfileClick',
    KPG_SM_GENERAL_CLICK: 'kpgSmGeneralClick',
    KPG_SM_CMS_BLOCK_REQUIRED_CLICK: 'kpgSmCmsBlockRequiredClick',
    KPG_SM_CMS_REQUIRED_CLICK: 'kpgSmCmsRequiredClick',
    KPG_SM_CMS_BLOCK_REQUIRING_CLICK: 'kpgSmCmsBlockRequiringClick',
    KPG_SM_CMS_REQUIRING_CLICK: 'kpgSmCmsRequiringClick',
    KPG_SM_CMS_PROCEEDS_CLICK: 'kpgSmCmsProceedsClick',
    KPG_SM_CMS_CLICK: 'kpgSmCmsClick',
    KPG_SM_KEMI_ANALYTICS_CLICK: 'kpgSmKemiAnalyticsClick',
    KPG_SM_INSTA_ANALYTICS_CLICK: 'kpgSmInstaAnalyticsClick',
    KPG_SM_CHANNELTALK_CLICK: 'kpgSmChanneltalkClick',
    KPG_SM_KEMIGUIDE_CLICK: 'kpgSmKemiguideClick',
    KPG_SM_CART_CLICK: 'kpgSmCartClick',
    KPG_SM_ORDERLIST_CLICK: 'kpgSmOrderlistClick',
    KPG_SM_SC_BLOCK_NEWORDER_CLICK: 'kpgSmScBlockNeworderClick',
    KPG_SM_SC_BLOCK_TODAYSALES_CLICK: 'kpgSmScBlockTodaysalesClick',
    KPG_SM_SC_CONSUMERADM_CLICK: 'kpgSmScConsumeradmClick',
    KPG_SM_SC_EXCHANGEADM_CLICK: 'kpgSmScExchangeadmClick',
    KPG_SM_SC_BLOCK_EXCHANGEREQ_CLICK: 'kpgSmScBlockExchangereqClick',
    KPG_SM_MAIN_CLICK: 'kpgSmMainClick',
    KPG_SM_SC_REFUNDADM_CLICK: 'kpgSmScRefundadmClick',
    KPG_SM_SC_BLOCK_REFUNDREQ_CLICK: 'kpgSmScBlockRefundreqClick',
    KPG_SM_SC_REGISTPRODUCT_CLICK: 'kpgSmScRegistproductClick',
    KPG_SM_SC_PRODUCTLIST_CLICK: 'kpgSmScProductlistClick',
    KPG_SM_SC_CANCELADM_CLICK: 'kpgSmScCanceladmClick',
    KPG_SM_SC_ENTIREORDER_CLICK: 'kpgSmScEntireorderClick',
    KPG_SM_SC_SALES_ANALYTICS_CLICK: 'kpgSmScSalesAnalyticsClick',
    KPG_SM_SC_CLICK: 'kpgSmScClick',
    SC_CONSUMERADM_POPUP: 'scConsumeradmPopup',
  },
  KPG_PERSONALINFO: {
    KPG_PERSONALINFO_SWITCH_TYPE_CLICK: 'kpgPersonalinfoSwitchTypeClick',
    KPG_PERSONALINFO_SELFCHECK_CLICK: 'kpgPersonalinfoSelfcheckClick',
    SCREEN_OPEN_SELFCHECK_POPUP: 'screenOpenSelfcheckPopup',
    SELFCHECK_POPUP_CLOSE: 'selfcheckPopupClose',
    KPG_PERSONALINFO_SELFCHECKREQ_CLICK: 'kpgPersonalinfoSelfcheckreqClick',
    KPG_PERSONALINFO_SELF_RECHECK_CLICK: 'kpgPersonalinfoSelfRecheckClick',
    KPG_PERSONALINFO_BANKCHECK_CLICK: 'kpgPersonalinfoBankcheckClick',
    SCREEN_OPEN_BANKCHECK_POPUP: 'screenOpenBankcheckPopup',
    BANKCHECK_POPUP_CLOSE: 'bankcheckPopupClose',
    KPG_PERSONALINFO_BANKCHECKREQUEST_CLICK:
      'kpgPersonalinfoBankcheckrequestClick',
    KPG_PERSONALINFO_BANK_RECHECK_CLICK: 'kpgPersonalinfoBankRecheckClick',
    KPG_PERSONALINFO_BIZCHECK_CLICK: 'kpgPersonalinfoBizcheckClick',
    KPG_PERSONALINFO_BIZ_BANKCHECK_CLICK: 'kpgPersonalinfoBizBankcheckClick',
  },
  KPG_ANALYTICS: {
    SCREEN_OPEN_KEMI_ANALYTICS: 'screen_open_kemi_analytics',
    SCREEN_OPEN_SALES_ANALYTICS: 'screen_open_sales_analytics',
    SCREEN_OPEN_SALES_ANALYTICS_FOR_CARDS:
      'screen_open_sales_analytics_for_cards',
    SCREEN_OPEN_INSTA_ANALYTICS: 'screen_open_insta_analytics',
  },
  KEMI_PRODUCT_DETAIL: {
    SCREEN_OPEN_KEMIPRODUCT_DETAIL: 'screen_open_kemiproduct_detail',
    KEMI_PRODUCTDETAIL_CLICK_PREPAGE: 'kemi_productdetail_click_prepage',
    KEMI_PRODUCTDETAIL_CLICK_BUY: 'kemi_productdetail_click_buy',
    KEMI_PRODUCTDETAIL_CLICK_OPTION: 'kemi_productdetail_click_option',
    SCREEN_OPEN_KEMIPRODUCT_DETAIL_QUANITY_POPUP:
      'screen_open_kemiproduct_detail_quanity_popup',
    KEMIPRODUCT_DETAIL_QUANITY_POPUP_CLICK_BUY:
      'kemiproduct_detail_quanity_popup_click_buy',
    KEMIPRODUCT_DETAIL_QUANITY_POPUP_CLICK_SET_QUANITY:
      'kemiproduct_detail_quanity_popup_click_set_quanity',
    KEMIPRODUCT_DETAIL_QUANITY_POPUP_CLICK_ADD_CART:
      'kemiproduct_detail_quanity_popup_click_add_cart',
    KEMIPRODUCT_DETAIL_QUANITY_POPUP_CLICK_CLOSE:
      'kemiproduct_detail_quanity_popup_click_close',
    SCREEN_OPEN_KEMIPRODUCT_DETAIL_OPTION_POPUP:
      'screen_open_kemiproduct_detail_option_popup',
    KEMIPRODUCT_DETAIL_OPTION_CLICK_OPTION:
      'kemiproduct_detail_option_click_option',
    KEMIPRODUCT_DETAIL_OPTION_CLICK_CLOSE:
      'kemiproduct_detail_option_click_close',
    SCREEN_OPEN_KEMIPRODUCT_DETAIL_LOWEROPTION_POPUP:
      'screen_open_kemiproduct_detail_loweroption_popup',
    KEMIPRODUCT_DETAIL_LOWEROPTION_CLICK_PREPAGE:
      'kemiproduct_detail_loweroption_click_prepage',
    KEMIPRODUCT_DETAIL_LOWEROPTION_CLICK_CLOSE:
      'kemiproduct_detail_loweroption_click_close',
    KEMIPRODUCT_DETAIL_LOWEROPTION_CLICK_SET_QUANITY:
      'kemiproduct_detail_loweroption_click_set_quanity',
    KEMIPRODUCT_DETAIL_LOWEROPTION_CLICK_CLICK_OPTION:
      'kemiproduct_detail_loweroption_click_click_option',
    KEMIPRODUCT_DETAIL_LOWEROPTION_CLICK_CLICK_ADD_CART:
      'kemiproduct_detail_loweroption_click_click_add_cart',
    KEMIPRODUCT_DETAIL_LOWEROPTION_CLICK_CLICK_DONE:
      'kemiproduct_detail_loweroption_click_click_done',
    SC_PRODUCT_INQUIRY_REG_ANSWER_CLICK: 'scProductInquiryRegAnswerClick',
    SC_PRODUCT_REVIEW_REG_ANSWER_CLICK: 'scProductReviewRegAnswerClick',
    PRODUCT_DETAIL_REGISTER_INQUIRY_CLICK: 'productdetailRegisterInquiryClick',
    KEMI_SALES_START_ALIM_CLICK: 'kemiSalesStartAlimClick',
    KEMI_SALES_START_ALIM_DONE: 'kemiSalesStartAlimDone',
    KEMI_SALES_START_ALIM_CLOSE: 'kemiSalesStartAlimClose',
    KEMI_SALES_START_ALIM_VIEW: 'kemiSalesStartAlimView',
    KEMI_PRODUCT_DETAIL_SHARE_CLICK: 'kemiProductDetailShareClick',
    SCREEN_OPENKEMI_PRODUCT_DETAIL_OPTION: 'screenOpenkemiProductDetailOption',
    KEMI_PRODUCT_DETAIL_MODAL_BASKET_CLICK: 'kemiProductDetailModalBasketClick',
    KEMI_PRODUCT_DETAIL_MODAL_BUY_CLICK: 'kemiProductDetailModalBuyClick',
    KEMI_PRODUCT_DETAIL_ERROR_TOAST: 'kemiProductDetailErrorToast',
    KEMI_PRODUCT_DETAIL_TO_HOME_CLICK: 'kemiProductDetailToHomeClick',
    KEMI_PRODUCT_DETAIL_TO_BASKET_CLICK: 'kemiProductDetailToBasketClick',
    KEMI_PRODUCT_DETAIL_INQUIRY_CLICK: 'kemiProductDetailInquiryClick',
    KEMI_PRODUCT_DETAIL_ALIM_CLICK: 'kemiProductDetailAlimClick',
    KEMI_PRODUCT_DETAIL_ALIM_COMPLETE: 'kemiProductDetailAlimComplete',
  },
  KEMI_CART: {
    SCREEN_OPEN_KEMI_CART: 'screen_open_kemi_cart',
    KEMI_CART_CLICK_ITEMGROUP_ORDER: 'kemi_cart_click_itemgroup_order',
    KEMI_CART_CLICK_ORDER: 'kemi_cart_click_order',
    KEMI_CART_CLICK_SET_QUANTITY: 'kemi_cart_click_set_quantity',
    KEMI_CART_CLICK_DELETE: 'kemi_cart_click_delete',
    KEMI_CART_CLICK_SELLER: 'kemi_cart_click_seller',
    KEMI_BASKET_SINGLE_ORDER_CLICK: 'kemiBasketSingleOrderClick',
    KEMI_BASKET_TOTAL_ORDER_CLICK: 'kemiBasketTotalOrderClick',
    KEMI_BASKET_SINGLE_PRODUCT_DELETE_CLICK:
      'kemiBasketSingleProductDeleteClick',
    SCREEN_OPEN_BASKET_MODAL: 'screenOpenBasketModal',
    KEMI_BASKET_SELETED_PRODUCT_DELETE_MODAL:
      'kemiBasketSeletedProductDeleteModal',
    KEMI_BASKET_ADDITIONAL_CART_MODAL: 'kemiBasketAdditionalCartModal',
    KEMI_BASKET_NOT_AVAILABLE_DELETE: 'kemiBasketNotAvailableDelete',
  },
  KEMI_CHECKOUT: {
    SCREEN_OPEN_KEMI_CHECKOUT: 'screen_open_kemi_checkout',
    KEMI_CHECKOUT_CLICK_PREPAGE: 'kemi_checkout_click_prepage',
    KEMI_CHECKOUT_CLICK_COPY_ORDERER_INFO_CHECKBOX:
      'kemi_checkout_click_copy_orderer_info_checkbox',
    KEMI_CHECKOUT_CLICK_SEARCH_ADDRESS: 'kemi_checkout_click_search_address',
    KEMI_CHECKOUT_CLICK_PAYMENT_METHOD: 'kemi_checkout_click_payment_method',
    KEMI_CHECKOUT_CLICK_PAYMENT_START: 'kemi_checkout_click_payment_start',
    KEMI_CHECKOUT_CLICK_PCC_INQUARY_GUIDE:
      'kemi_checkout_click_pcc_inquary_guide',
    KEMI_CHECKOUT_PAYMENT_ERROR_TOAST: 'kemi_checkout_payment_error_toast',
    KEMI_CHECKOUT_SET_AS_DEFAULT: 'kemiCheckoutSetAsDefault',
    KEMI_ORDER_CHECK_SET_DEFAULT_CLICK: 'kemiOrderCheckSetDefaultClick',
    KEMI_ORDER_CHECK_ORDERER_SAME_CLICK: 'kemiOrderCheckOrdererSameClick',
    KEMI_ORDER_CHECK_PAYMENT_CLICK: 'kemiOrderCheckPaymentClick',
  },
  KEMI_PAYMENT_DONE: {
    SCREEN_OPEN_KEMI_PAYMENT_DONE: 'screen_open_kemi_payment_done',
    SCREEN_OPEN_PAYMENT_FAIL_POPUP: 'screenOpenPaymentFailPopup',
    KEMI_PAYMENT_CLICK_CLOSE: 'kemi_payment_click_close',
    KEMI_PAYMENT_DONE_CLICK_EDIT_SHIPPING_INFO:
      'kemi_payment_done_click_edit_shipping_info',
    KEMI_PAYMENT_DONE_CLICK_GO_HOME: 'kemi_payment_done_click_go_home',
    KEMI_PAYMENT_DONE_TO_HOME_CLICK: 'kemiPaymentDoneToHomeClick',
    KEMI_PAYMENT_DONE_TO_ORDER_LIST_CLICK: 'kemiPaymentDoneToOrderListClick',
  },
  KEMI_EDIT_SHIPPING_INFO: {
    SCREEN_OPEN_KEMI_EDIT_SHIPPING_INFO: 'screen_open_kemi_edit_shipping_info',
    KEMI_EDIT_SHIPPING_INFO_CLICK_PREPAGE:
      'kemi_edit_shipping_info_click_prepage',
    KEMI_EDIT_SHIPPING_INFO_CLICK_SEARCH_ADDRESS:
      'kemi_edit_shipping_info_click_search_address',
    KEMI_EDIT_SHIPPING_INFO_CLICK_SAVE: 'kemi_edit_shipping_info_click_save',
    SCREEN_OPEN_ORDER_DETAIL_SHIPPING_INFO_EDIT:
      'screenOpenOrderDetailShippingInfoEdit',
  },
  KEMI_PCC_INQUARY_GUIDE: {
    SCREEN_OPEN_KEMI_PCC_INQUARY_GUIDE: 'screen_open_kemi_pcc_inquary_guide',
    KEMI_PCC_INQUARY_GUIDE_CLICK_PREPAGE:
      'kemi_pcc_inquary_guide_click_prepage',
  },
  ONBOARDING: {
    SCREEN_OPEN_INBOARDING_LINKNAME: 'screenOpenOnboardingLinkname',
    ONBOARDING_LINKNAME_NEXT_CLICK: 'onboardingLinknameNextClick',
    SCREEN_OPEN_ONBOARDING_USER_TYPE: 'screenOpenOnboardingUsertype',
    ONBOARDING_USER_TYPE_NEXT_CLICK: 'onboardingUserTypeNextClick',
    ONBOARDING_USER_TYPE_LATER_CLICK: 'onboardingUsertypeLaterClick',
    ONBOARDING_USER_TYPE_NEXT_TIME_CLICK: 'onboardingUsertypeNexttimeClick',
    SCREEN_OPEN_ONBOARDING_SALES_TYPE: 'screenOpenOnboardingSalestype',
    ONBOARDING_SALES_TYPE_NEXT_CLICK: 'onboardingSalestypeNextClick',
    SCREEN_OPEN_ONBOARDING_SNS_FREQUENCY: 'screenOpenOnboardingSNSfrequency',
    ONBOARDING_SNS_FREQUENCY_NEXT_CLICK: 'onboardingSNSfrequencyNextClick',
    SCREEN_OPEN_ONBOARDING_DONE: 'screenOpenOnboardingDone',
    ONBOARDING_DONE_TRY_CLICK: 'onboardingDoneTryClick',
    ONBOARDING_DONE_DONE_CLICK: 'onboardingDoneDoneClick',
  },
  KEMI_ONBOARDING_LOADING: {
    SCREEN_OPEN_KEMI_ONBOARDING_LOADING: 'screen_open_kemi_onboarding_loading',
  },
  KEMI_ONBOARDING_NAME: {
    SCREEN_OPEN_KEMI_ONBOARDING_NAME: 'screen_open_kemi_onboarding_name',
    KEMI_ONBOARDING_NAME_CLICK_LATER: 'kemi_onboarding_name_click_later',
    KEMI_ONBOARDING_NAME_CLICK_DONE: 'kemi_onboarding_name_click_done',
  },
  KEMI_ONBOARDING_LINK: {
    SCREEN_OPEN_KEMI_ONBOARDING_LINK: 'screen_open_kemi_onboarding_link',
    KEMI_ONBOARDING_LINK_CLICK_LATER: 'kemi_onboarding_link_click_later',
    KEMI_ONBOARDING_LINK_CLICK_DONE: 'kemi_onboarding_link_click_done',
  },
  KEMI_ONBOARDING_MULTILINK: {
    SCREEN_OPEN_KEMI_ONBOARDING_MULTILINKS:
      'screen_open_kemi_onboarding_multilinks',
    KEMI_ONBOARDING_MULTILINK_CLICK_INPUT:
      'kemi_onboarding_multilinks_input_click',
    KEMI_ONBOARDING_MULTILINK_CLICK_NEXT_BUTTON:
      'kemi_onboarding_multilinks_next_btn_click',
    KEMI_ONBOARDING_MULTILINK_CLICK_DONT_HAVE_BUTTON:
      'kemi_onboarding_multilinks_donthave_btn_click',
    KEMI_ONBOARDING_MULTILINK_CLICK_LATER:
      'kemi_onboarding_multilinks_later_btn_click',
    SCREEN_OPEN_KEMI_ONBOARDING_MULTILINK_SUCCESS:
      'screen_open_kemi_onboarding_multilinks_success',
    SCREEN_OPEN_KEMI_ONBOARDING_MULTILINK_FAIL:
      'screen_open_kemi_onboarding_multilinks_fail',
  },
  KEMI_ONBOARDING_PROFILE: {
    SCREEN_OPEN_KEMI_ONBOARDING_PROFILE: 'screen_open_kemi_onboarding_profile',
    KEMI_ONBOARDING_PROFILE_CLICK_LATER: 'kemi_onboarding_profile_click_later',
    KEMI_ONBOARDING_PROFILE_CLICK_UPLOAD:
      'kemi_onboarding_profile_click_upload',
    KEMI_ONBOARDING_PROFILE_CLICK_DONE: 'kemi_onboarding_profile_click_done',
  },
  KEMI_ONBOARDING_LINK_SHARE: {
    SCREEN_OPEN_KEMI_ONBOARDING_LINK_SHARE_POPUP:
      'screen_open_kemi_onboarding_link_share_popup',
    KEMI_ONBOARDING_LINK_SHARE_CLICK_COPY_LINK:
      'kemi_onboarding_link_share_click_copy_link',
    KEMI_ONBOARDING_LINK_SHARE_CLICK_LATER:
      'kemi_onboarding_link_share_click_later',
  },
  KEMI_ONBOARDING_LINK_SHARE_LATER: {
    SCREEN_OPEN_KEMI_ONBOARDING_LINK_SHARE_POPUP_LATER:
      'screen_open_kemi_onboarding_link_share_popup_later',
    KEMI_ONBOARDING_LINK_SHARE_LATER_CLICK_ADD_CARED:
      'kemi_onboarding_link_share_later_click_add_cared',
    KEMI_ONBOARDING_LINK_SHARE_LATER_CLICK_GOT_IT:
      'kemi_onboarding_link_share_later_click_got_it',
  },
  KEMI: {
    SCREEN_OPEN_KEMIHOME: 'screen_open_kemihome',
    KEMI_CLICK_GO_TO_LANDING: 'kemi_click_go_to_landing',
    KEMI_CLICK_SHARE: 'kemi_click_share',
    KEMI_CLICK_CARD: 'kemi_click_card',
    KEMI_CLICK_SNS: 'kemi_click_sns',
  },
  GLOBAL_PAGE_OPEN: {
    KEMI: 'screen_open_kemi',
    KPG: 'screen_open_kpg',
    VISIT_KEMI: 'visit_kemi',
  },
  GENERIC_ERROR: {
    SCREEN_OPEN_GENERIC_ERROR: 'screen_open_generic_error',
  },
  NOTFOUND_ERROR: {
    SCREEN_OPEN_NOTFOUND_ERROR: 'screen_open_notfound_error',
  },
  TOAST_ERROR: {
    TOAST_ERROR: 'toast_error',
  },
  KPG_SELLER_PRODUCT_LIST: {
    SCREEN_OPEN_KPG_SELLER_PRODUCT_LIST: 'screenOpenKpgProductList',
    KPG_SELLER_PRODUCT_LIST_CLICK_PREPAGE: 'kpgProductListClickPrepage',
    KPG_SELLER_PRODUCT_LIST_CLICK_PRODUCT: 'kpgProductListClickProduct',
    KPG_PRODUCT_LIST_CATEGORY_CLICK: 'kpgProductListCategoryClick',
    KPG_PRODUCT_LIST_INSTA_BANNAER_CLICK: 'kpgProductListInstaBannaerClick',
  },
  KPG_SELLER_PRODUCT_DETAIL: {
    SCREEN_OPEN_KPG_SELLER_PRODUCT_DETAIL:
      'screen_open_kpg_seller_product_detail',
    KPG_SELLER_PRODUCT_DETAIL_CLICK_PREPAGE:
      'kpg_seller_product_detail_click_prepage',
    KPG_SELLER_PRODUCT_DETAIL_CLICK_PROFIT_PROJECTION:
      'kpg_seller_product_detail_click_profit_projection',
    KPG_SELLER_PRODUCT_DETAIL_CLICK_DONE:
      'kpg_seller_product_detail_click_done',
    KPG_SELLER_PRODUCT_DETAIL_CLICK_OPTION:
      'kpg_seller_product_detail_click_option',
    SCREEN_OPEN_KPG_SELLER_PRODUCT_DETAIL_OPTION_POPUP:
      'screen_open_kpg_seller_product_detail_option_popup',
    KPG_SELLER_PRODUCT_DETAIL_OPTION_POPUP_CLICK_CLOSE:
      'kpg_seller_product_detail_option_popup_click_close',
    KPG_SELLER_PRODUCT_DETAIL_OPTION_POPUP_CLICK_OPTION:
      'kpg_seller_product_detail_option_popup_click_option',
    KPG_SELLER_PRODUCT_DETAIL_OPTION_POPUP_CLICK_QUANITY:
      'kpg_seller_product_detail_option_popup_click_quanity',
  },
  KEMI_ADDCARD_PRODUCT: {
    SCREEN_OPEN_KPGADDPRODUCT: 'screen_open_kpgaddproduct',
    SCREEN_OPEN_KPG_ADDCARD_KEMI_PRODUCT: 'screenOpenKpgAddcardKemiProduct',
    KPG_ADDCARDPRODUCT_PRICE_FOCUS_OUT: 'kpg_addcardproduct_price_focus_out',
    KPG_ADDCARD_KEMI_PRODUCT_CLICK_NAME_FOCUS_OUT:
      'kpg_addcardproduct_click_name_focus_out',
    KPG_ADDCARDPRODUCT_CLICK_PROFIT_PROJECTION:
      'kpg_addcardproduct_click_profit_projection',
    KPG_ADDCARDPRODUCT_CLICK_IMG_TOGGLE: 'kpg_addcardproduct_click_img_toggle',
    KPG_ADDCARDPRODUCT_CLICK_IMGTAB: 'kpg_addcardproduct_click_imgtab',
    KPG_ADDCARDPRODUCT_CLICK_IMGSIZE: 'kpg_addcardproduct_click_imgsize',
    KPG_ADDCARDPRODUCT_CLICK_CHANGEEMOJI:
      'kpg_addcardproduct_click_changeemoji',
    KPG_ADDCARDPRODUCT_CLICK_ADD: 'kpg_addcardproduct_click_add',
    KPG_PRODUCT_CARD_ADD_SUCCESS: 'kpgProductCardAddSuccess',
  },
  KEMI_ORDERDETAIL: {
    SCREEN_OPEN_KEMI_ORDERDETAIL: 'screen_open_kemi_orderdetail',
    KEMI_ORDERDETAIL_CLICK_DELIVERY_CHECK:
      'kemi_orderdetail_click_delivery_check',
    KEMI_ORDERDETAIL_CLICK_ORDER_CANCEL: 'kemi_orderdetail_click_order_cancel',
    KEMI_ORDERDETAIL_CLICK_ORDER_EXCHANGE_RETURN:
      'kemi_orderdetail_click_order_exchange_return',
    KEMI_ORDERDETAIL_CLICK_INQUIRY: 'kemi_orderdetail_click_inquiry',
    ORDER_DETAIL_REGISTER_REVIEW_CLICK: 'orderdetailRegisterReviewClick',
    KEMI_ORDER_LIST_REVIEW_CLICK: 'kemiOrderListReviewClick',
    SCREEN_OPEN_REVIEW: 'screenOpenReview',
    KEMI_ORDER_LIST_REVIEW_SAVE_CLICK: 'kemiOrderListReviewSaveClick',
    KEMI_ORDER_LIST_REVIEW_EDIT_CLICK: 'kemiOrderListReviewEditClick',
    KEMI_ORDER_LIST_REVIEW_DELETE_CLICK: 'kemiOrderListReviewDeleteClick',
  },
  KEMI_ORDER_LIST: {
    KEMI_ORDER_LIST_ADDITIONAL_ERROR_TOAST: 'kemiOrderListAdditionalErrorToast',
    KEMI_ORDER_LIST_INQUIRY_CLICK: 'kemiOrderListInquiryClick',
    SCREEN_OPEN_EXCHANGE_REQUEST: 'screenOpenExchangeRequest',
    SCREEN_OPEN_EXCHANGE_DETAIL: 'screenOpenExchangeDetail',
    KEMI_EXCHANGE_REQUEST_CLICK: 'kemiExchangeRequestClick',
    SCREEN_OPEN_RETURN_REQUEST: 'screenOpenReturnRequest',
    KEMI_RETURN_REQUEST_CLICK: 'kemiReturnRequestClick',
    SCREEN_OPEN_RETURN_DETAIL: 'screenOpenReturnDetail',
    SCREEN_OPEN_CANCEL_REQUEST: 'screenOpenCancelRequest',
    SCREEN_OPEN_CANCEL_DETAIL: 'screenOpenCancelDetail',
    SCREEN_OPEN_CANCEL_MODAL: 'screenOpenCancelModal',
    KEMI_CANCEL_REQUEST_CLICK: 'kemiCancelRequestClick',
    SCREEN_OPEN_TRACKING_SHIPMENT: 'screenOpenTrackingShipment',
  },
  SELLER_CENTER: {
    SCREEN_OPEN_SELLER_CENTER: 'screen_open_sellercenter',
    SELLER_CENTER_ORDER_ADMIN_CLICK: 'sellercenter_orderadmin_click',
    SELLER_CENTER_SALES_ANALYTICS_CLICK: 'sellercenter_salesanalytics_click',
    SELLER_CENTER_ORDER_ADMIN_STATE: 'sellercenter_orderadmin_state',
    SCREEN_OPEN_SC_ENTIRE_ORDER: 'screenOpenScEntireorder',
    SCREEN_OPEN_SC_EXCHANGE_ADM: 'screenOpenScExchangeadm',
    SCREEN_OPEN_SC_REFUND_ADM: 'screenOpenScRefundadm',
    SCREEN_OPEN_SC_CANCEL_ADM: 'screenOpenScCanceladm',
    SC_ORDER_ADMIN_FILTER_CLICK: 'scOrderadminFilterClick',
    SC_ENTIRE_ORDER_PRESET_CLICK: 'scEntireorderPresetClick',
    SC_EXCHNAGE_ADM_PRESET_CLICK: 'scExchangeadmPresetClick',
    SC_REFUND_ADM_PRESET_CLICK: 'scRefundadmPresetClick',
    SC_SEARCH_ENTER_CLICK: 'scSearchEnterClick',
    SC_ALL_LIST_DOWNLOAD_CLICK: 'scAllListDownloadClick',
    SC_DELIVERY_NO_UPLOAD_CLICK: 'scDeliveryNoUploadClick',
    SCREEN_OPEN_SC_DELIVERY_NO_UPLOAD_POPUP:
      'screenOpenScDeliveryNoUploadPopup',
    SC_DELIVERY_NO_UPLOAD_FORM_DOWNLOAD_CLICK:
      'scDeliveryNoUploadFormDownloadClick',
    SC_DELIVERY_NO_UPLOAD_DONE_CLICK: 'scDeliveryNoUploadDoneClick',
    SCREEN_OPEN_SC_DELIVERY_NO_UPLOAD_DONE_POPUP:
      'screenOpenScDeliveryNoUploadDonePopup',
    SC_ENTIRE_ORDER_DELIVERY_CORP_INPUT_CLICK:
      'scEntireorderDeliveryCorpInputClick',
    SC_ENTIRE_ORDER_DELIVERY_NUMBER_INPUT_CLICK:
      'scEntireorderDeliveryNumberInputClick',
    SC_ENTIRE_ORDER_MAKE_DELIVERY_CLICK: 'scEntireorderMakeDeliveryClick',
    SC_ENTIRE_ORDER_READY_DELIVERY_CLICK: 'scEntireorderReadyDeliveryClick',
    SC_TO_ORDER_CANCEL_CLICK: 'scToOrderCancelClick',
    SCREEN_OPEN_SC_TO_ORDER_CANCEL_POPUP: 'screenOpenScToOrderCancelPopup',
    SC_TO_ORDER_CANCEL_REASON_CLICK: 'scToOrderCancelReasonClick',
    SC_TO_ORDER_CANCEL_REASON_EXP_INPUT_CLICK:
      'scToOrderCancelReasonExpInputClick',
    SC_TO_ORDER_CANCEL_BANK_CLICK: 'scToOrderCancelBankClick',
    SC_TO_ORDER_CANCEL_ACCOUNT_NUMBER_INPUT_CLICK:
      'scToOrderCancelAccountNumberInputClick',
    SC_TO_ORDER_CANCEL_ACCOUNT_HOLDER_INPUT_CLICK:
      'scToOrderCancelAccountHolderInputClick',
    SC_TO_ORDER_CANCEL_DONE_CLICK: 'scToOrderCancelDoneClick',
    SCREEN_OPEN_SC_TO_ORDER_CANCEL_HELP_ALERT_POPUP:
      'screenOpenScToOrderCancelHelpAlertPopup',

    SC_TO_PAYMENT_COMPLETED_CLICK: 'scToPaymentCompletedClick',
    SC_TO_DELIVERY_COMPLETED_CLICK: 'scToDeliveryCompletedClick',
    SC_TO_EXCHANGE_START_CLICK: 'scToExchangeStartClick',
    SCREEN_OPEN_SC_TO_EXCHANGE_START_POPUP: 'screenOpenScToExchangeStartPopup',
    SC_TO_EXCHANGE_START_REASON_CLICK: 'scToExchangeStartReasonClick',
    SC_TO_EXCHANGE_START_REASON_EXP_INPUT_CLICK:
      'scToExchangeStartReasonExpInputClick',
    SC_TO_EXCHANGE_START_DONE_CLICK: 'scToExchangeStartDoneClick',

    SCREEN_OPEN_SC_TO_EXCHANGE_START_HELP_ALERT_POPUP:
      'screenOpenScToExchangeStartHelpAlertPopup',
    SC_TO_RETURN_START_CLICK: 'scToReturnStartClick',
    SCREEN_OPEN_SC_TO_RETURN_START_POPUP: 'screenOpenScToReturnStartPopup',
    SC_TO_RETURN_START_REASON_CLICK: 'scToReturnStartReasonClick',
    SC_TO_RETURN_START_REASON_EXP_INPUT_CLICK:
      'scToReturnStartReasonExpInputClick',
    SC_TO_RETURN_START_DONE_CLICK: 'scToReturnStartDoneClick',

    SCREEN_OPEN_SC_TO_RETURN_START_HELP_ALERT_POPUP:
      'screenOpenScToReturnStartHelpAlertPopup',
    SC_TO_EXCHANGE_DELIVERY_CLICK: 'scToExchangeDeliveryClick',
    SCREEN_OPEN_SC_TO_EXCHANGE_DELIVERY_POPUP:
      'screenOpenScToExchangeDeliveryPopup',
    SC_TO_EXCHANGE_DELIVERY_DONE_CLICK: 'scToExchangeDeliveryDoneClick',
    SC_TO_EXCHANGE_COMPLETED_CLICK: 'scToExchangeCompletedClick',
    SC_TO_EXCHANGE_CANCEL_CLICK: 'scToExchangeCancelClick',
    SCREEN_OPEN_SC_TO_EXCHANGE_CANCEL_POPUP:
      'screenOpenScToExchangeCancelPopup',

    SC_TO_EXCHANGE_CANCEL_REASON_INPUT_CLICK:
      'scToExchangeCancelReasonInputClick',
    SC_TO_EXCHANGE_CANCEL_DONE_CLICK: 'scToExchangeCancelDoneClick',
    SCREEN_OPEN_SC_TO_EXCHANGE_CANCEL_HELP_ALERT_POPUP:
      'screenOpenScToExchangeCancelHelpAlertPopup',
    SC_TO_RETURN_ARRIVED_CLICK: 'scToReturnArrivedClick',
    SC_TO_REFUND_CLICK: 'scToRefundClick',
    SCREEN_OPEN_SC_TO_REFUND_POPUP: 'screenOpenScToRefundPopup',
    SC_TO_REFUND_REASON_CLICK: 'scToRefundReasonClick',
    SC_TO_REFUND_REASON_EXP_INPUT_CLICK: 'scToRefundReasonExpInputClick',

    SC_TO_REFUND_BANK_CLICK: 'scToRefundBankClick',
    SC_TO_REFUND_ACCOUNT_NUMBER_INPUT_CLICK:
      'scToRefundAccountNumberInputClick',
    SC_TO_REFUND_ACCOUNT_HOLDER_INPUT_CLICK:
      'scToRefundAccountHolderInputClick',
    SC_TO_REFUND_DONE_CLICK: 'scToRefundDoneClick',
    SCREEN_OPEN_SC_TO_REFUND_HELP_ALERT_POPUP:
      'screenOpenScToRefundHelpAlertPopup',
    SC_TO_RETURN_CANCEL_CLICK: 'scToReturnCancelClick',
    SCREEN_OPEN_SC_TO_RETURN_CANCEL_POPUP: 'screenOpenScToReturnCancelPopup',
    SC_TO_RETURN_CANCEL_REASON_INPUT_CLICK: 'scToReturnCancelReasonInputClick',
    SC_TO_RETURN_CANCEL_DONE_CLICK: 'scToReturnCancelDoneClick',
    SCREEN_OPEN_SC_TO_RETURN_CANCEL_HELP_ALERT_POPUP:
      'screenOpenScToReturnCancelHelpAlertPopup',
    SCREEN_OPEN_SC_CHANGE_STATE_ERROR_ALERT_POPUP:
      'screenOpenScChangeStateErrorAlertPopup',
    SCREEN_OPEN_SC_SETTLEMENT_SUMMARY: 'screenOpenScSettlementSummary',
    SCREEN_OPEN_SC_SETTLEMENT_DETAILS: 'screenOpenScSettlementDetails',
    SCREEN_OPEN_SC_SETTLEMENT_INFO: 'screenOpenScSettlementInfo',
    SC_SETTLEMENT_SUMMARY_PRICE_BLOCK_CLICK:
      'scSettlementSummaryPriceBlockClick',
    SC_SETTLEMENT_SUMMARY_PRICE_ORDER_CLICK:
      'scSettlementSummaryOrderBlockClick',
    SC_SETTLEMENT_SUMMARY_DOWNLOAD_CLICK: 'scSettlementSummaryDownloadClick',
    SC_SETTLEMENT_DETAILS_SEARCH_CLICK: 'scSettlementDetailsSearchClick',
    SC_SETTLEMENT_DETAILS_ORDER_ID_CLICK: 'scSettlementDetailsOrderIdClick',
    SC_SETTLEMENT_DETAILS_DOWNLOAD_CLICK: 'scSettlementDetailsDownloadClick',
    SC_SETTLEMENT_INFO_PERSONAL_INFO_CLICK: 'scSettlementInfoPersonalInfoClick',
  },
  KPG_SELLERCENTER: {
    SCREEN_OPEN_SC_REGIST_PRODUCT: 'screenOpenScRegistproduct',
    SCREEN_OPEN_KPG_ADDCARD_MYPRODUCT: 'screenOpenKpgAddcardMyProduct',
    KPG_ADDCARD_MY_PRODUCT_PREPAGE_CLICK: 'kpgAddcardMyProductPrepageClick',
    KPG_ADDCARD_MY_PRODUCT_NAME_FOCUS_OUT: 'kpgAddcardMyProductNameFocusOut',
    SCREEN_OPEN_SC_EDIT_PRODUCT: 'screenOpenScEditProduct',
    SCREEN_OPEN_SC_PRODUCT_LIST: 'screenOpenScProductlist',
    KPG_ADDCARD_MY_PRODUCT_ADD_CLICK: 'kpgAddcardMyProductAddClick',
    KPG_ADDCARD_MY_PRODUCT_BADGE_FOCUS_OUT: 'kpgAddcardMyProductBadgeFocusOut',
    KPG_ADDCARD_MY_PRODUCT_BADGE_USE: 'kpgAddcardMyProductBadgeUse',
    KPG_ADDCARD_MY_PRODUCT_CHANGE_EMOJI_CLICK:
      'kpgAddcardMyProductChangeEmojiClick',
    KPG_ADDCARD_MY_PRODUCT_EDIT_INFO_CHECK: 'kpgAddcardMyProductEditInfoCheck',
    KPG_ADDCARD_MY_PRODUCT_HIDEPRICE_CHECK: 'kpgAddcardMyProductHidepriceCheck',
    KPG_ADDCARD_KEMI_PRODUCT_HIDEPRICE_CHECK:
      'kpgAddcardKemiProductHidepriceCheck',
    KPG_EDITCARD_KEMI_PRODUCT_HIDEPRICE_CHECK:
      'kpgEditcardKemiProductHidepriceCheck',
    KPG_ADDCARD_MY_PRODUCT_IMG_TOGGLE: 'kpgAddcardMyProductImgToggle',
    KPG_ADDCARD_MY_PRODUCT_IMGSIZE_CLICK: 'kpgAddcardMyProductImgsizeClick',
    KPG_ADDCARD_MY_PRODUCT_IMGTAB_CLICK: 'kpgAddcardMyProductImgtabClick',
    KPG_ADDCARD_MY_PRODUCT_NAMEINPUT_CLICK: 'kpgAddcardMyProductNameinputClick',
    KPG_ADDCARD_MY_PRODUCTPREPAGE_CLICK: 'kpgAddcardMyProductprepageClick',
    KPG_ADDCARD_MY_PRODUCT_PUBLIC_SET: 'kpgAddcardMyProductPublicSet',
    KPG_ADDCARD_MY_PRODUCT_SALES_PLAN_USE: 'kpgAddcardMyProductSalesPlanUse',
    KPG_ADDCARD_KEMI_PRODUCT_IMG_TOGGLE: 'kpgAddcardKemiProductImgToggle',
    KPG_ADDCARD_KEMI_PRODUCT_SALES_PLAN_USE:
      'kpgAddcardKemiProductSalesPlanUse',
    KPG_ADDCARD_KEMI_PRODUCT_BADGE_USE: 'kpgAddcardKemiProductBadgeUse',
    KPG_ADDCARD_KEMI_PRODUCT_BADGE_FOCUS_OUT:
      'kpgAddcardKemiProductBadgeFocusOut',
    KPG_ADDCARD_KEMI_PRODUCT_PUBLIC_SET: 'kpgAddcardKemiProductPublicSet',
    KPG_MY_PRODUCT_PREVIEW_EDIT_CLICK: 'kpgMyProductPreviewEditClick',
    KPG_MY_PRODUCT_PREVIEW_PICK_CLICK: 'kpgMyProductPreviewPickClick',
    KPG_MY_PRODUCT_PREVIEW_PREPAGE_CLICK: 'kpgMyProductPreviewPrepageClick',
    SC_EDIT_PRODUCT_CALLPRESAVE_CLICK: 'scEditProductCallpresaveClick',
    SC_EDIT_PRODUCT_CALLPRESAVE_POPUP_CLOSE:
      'scEditProductCallpresavePopupClose',
    SC_EDIT_PRODUCT_CALLPRESAVE_POPUP_DELETE:
      'scEditProductCallpresavePopupDelete',
    SC_EDIT_PRODUCT_CALLPRESAVE_POPUP_OPEN: 'scEditProductCallpresavePopupOpen',
    SC_EDIT_PRODUCT_CALLPRESAVE_POPUP_SELECT:
      'scEditProductCallpresavePopupSelect',
    SC_EDIT_PRODUCT_DELETE_CLICK: 'scEditProductDeleteClick',
    SC_EDIT_PRODUCT_DISCOUNT_PRICE_FOCUS_OUT:
      'scEditProductDiscountPriceFocusOut',
    SC_EDIT_PRODUCT_DISCOUNT_RATE_FOCUS_OUT:
      'scEditProductDiscountRateFocusOut',
    SC_EDIT_PRODUCT_IMAGE_CLICK: 'scEditProductImageClick',
    SC_EDIT_PRODUCT_NAME_FOCUS_OUT: 'scEditProductNameFocusOut',
    SC_EDIT_PRODUCT_OPTION_DELETE: 'scEditProductOptionDelete',
    SC_EDIT_PRODUCT_OPTION_NAME_FOCUS_OUT: 'scEditProductOptionNameFocusOut',
    SC_EDIT_PRODUCT_OPTION_PRICE_FOCUS_OUT: 'scEditProductOptionPriceFocusOut',
    SC_EDIT_PRODUCT_OPTION_STOCK_FOCUS_OUT: 'scEditProductOptionStockFocusOut',
    SC_EDIT_PRODUCT_PREVIEW_CLICK: 'scEditProductPreviewClick',
    SC_EDIT_PRODUCT_PRICE_FOCUS_OUT: 'scEditProductPriceFocusOut',
    SC_EDIT_PRODUCT_REGIONSHIP_SET: 'scEditProductRegionshipSet',
    SC_EDIT_PRODUCT_SALE_DATE_SET: 'scEditProductSaleDateSet',
    SC_EDIT_PRODUCT_SAVE_CLICK: 'scEditProductSaveClick',
    SC_EDIT_PRODUCT_SHIPPINGPRICE_TYPE_SET: 'scEditProductShippingpriceTypeSet',
    SC_EDIT_PRODUCT_STOCK_FOCUS_OUT: 'scEditProductStockFocusOut',
    SC_EDIT_PRODUCT_SWITCH_STATUS: 'scEditProductSwitchStatus',
    SC_KEMI_PRODUCTLIST_DISPLAY_CLICK: 'scKemiProductlistDisplayClick',
    SC_MY_PRODUCTLIST_CHIP_CLICK: 'scMyProductlistChipClick',
    SC_MY_PRODUCTLIST_DELETE_CLICK: 'scMyProductlistDeleteClick',
    SC_MY_PRODUCTLIST_DISPLAY_CLICK: 'scMyProductlistDisplayClick',
    SC_MY_PRODUCTLIST_DUPLICATE_CLICK: 'scMyProductlistDuplicateClick',
    SC_MY_PRODUCTLIST_EDIT_PRODUCT_CLICK: 'scMyProductlistEditProductClick',
    SC_MY_PRODUCTLIST_PRODUCT_CHECK: 'scMyProductlistProductCheck',
    SC_PRODUCTLIST_SEARCH_INPUT_FOCUS_OUT: 'scProductlistSearchInputFocusOut',
    SCREEN_OPEN_KPG_ADDCARD_MY_PRODUCT: 'screenOpenKpgAddcardMyProduct',
    SCREEN_OPEN_KPG_MY_PRODUCT_PREVIEW: 'screenOpenKpgMyProductPreview',
    SCREEN_OPEN_REG_PRODUCT_DISPLAY_POPUP: 'screenOpenRegProductDisplayPopup',
    SCREEN_OPEN_SC_EDITPRODUCT: 'screenOpenScEditproduct',
    SCREEN_OPEN_SC_REGISTPRODUCT: 'screenOpenScRegistproduct',
    SCREEN_OPEN_SC_REG_PRODUCT_EDITOR: 'screenOpenScRegProductEditor',
    SC_REG_PRODUCT_CALLPRESAVE_CLICK: 'scRegProductCallpresaveClick',
    SC_REG_PRODUCT_CALLPRESAVE_POPUP_CLOSE: 'scRegProductCallpresavePopupClose',
    SC_REG_PRODUCT_CALLPRESAVE_POPUP_DELETE:
      'scRegProductCallpresavePopupDelete',
    SC_REG_PRODUCT_CALLPRESAVE_POPUP_OPEN: 'scRegProductCallpresavePopupOpen',
    SC_REG_PRODUCT_CALLPRESAVE_POPUP_SELECT:
      'scRegProductCallpresavePopupSelect',
    SC_REG_PRODUCT_DISCOUNT_PRICE_FOCUS_OUT:
      'scRegProductDiscountPriceFocusOut',
    SC_REG_PRODUCT_DISCOUNT_RATE_FOCUS_OUT: 'scRegProductDiscountRateFocusOut',
    SC_REG_PRODUCT_DISPLAY_POPUP_CLICK: 'scRegProductDisplayPopupClick',
    SC_REG_PRODUCT_EDITOR_CANCEL_CLICK: 'scRegProductEditorCancelClick',
    SC_REG_PRODUCT_EDITOR_CANCEL_POPUP: 'scRegProductEditorCancelPopup',
    SC_REG_PRODUCT_EDITOR_SAVE_CLICK: 'scRegProductEditorSaveClick',
    SC_REG_PRODUCT_IMAGE_CLICK: 'scRegProductImageClick',
    SC_REG_PRODUCT_LOWEROPTION_USE: 'scRegProductLoweroptionUse',
    SC_REG_PRODUCT_NAME_FOCUS_OUT: 'scRegProductNameFocusOut',
    SC_REG_PRODUCT_OPTION_USE: 'scRegProductOptionUse',
    SC_REG_PRODUCT_OPTION_NAME_FOCUS_OUT: 'scRegProductOptionNameFocusOut',
    SC_REG_PRODUCT_OPTION_PRICE_FOCUS_OUT: 'scRegProductOptionPriceFocusOut',
    SC_REG_PRODUCT_PRESAVE_CLICK: 'scRegProductPresaveClick',
    SC_REG_PRODUCT_PREVIEW_CLICK: 'scRegProductPreviewClick',
    SC_REG_PRODUCT_PRICE_FOCUS_OUT: 'scRegProductPriceFocusOut',
    SC_REG_PRODUCT_SALE_DATE_SET: 'scRegProductSaleDateSet',
    SC_REG_PRODUCT_SAVE_CLICK: 'scRegProductSaveClick',
    SC_REG_PRODUCT_STOCK_FOCUS_OUT: 'scRegProductStockFocusOut',
    SC_REG_PRODUCT_OPTION_DELETE: 'scRegProductOptionDelete',
    SC_EDIT_PRODUCT_LOWEROPTION_USE: 'scEditProductLoweroptionUse',
    SC_PRODUCT_REGIST_OPTION_MOVING: 'scProductRegistOptionMoving',
    SC_PRODUCT_REGIST_LOWER_OPTION_MOVING: 'scProductRegistLowerOptionMoving',
    SC_PRODUCT_REGIST_ADDITIONAL_GROUP_MOVING:
      'scProductRegistAdditionalGroupMoving',
    SC_PRODUCT_REGIST_ADDITIONAL_MOVING: 'scProductRegistAdditionalMoving',
    SC_ADDITIONAL_PRODUCT_TOGGLE: 'scAdditionalProductToggle',
    SC_PRODUCT_BUNDLE_SHIPPING: 'scProductBundleShipping',
    SCREEN_OPEN_BUNDLE_SHIPPING_SET: 'screenOpenBundleShippingSet',
    SC_BUNDLE_SHIPPING_SET_CANCEL_CLICK: 'scBundleShippingSetCancelClick',
    SC_BUNDLE_SHIPPING_SET_SAVE_CLICK: 'scBundleShippingSetSaveClick',
  },
  COMMISSION: {
    SCREEN_OPEN_PHONENUMBER_POPUP: 'screen_open_phonenumber_popup',
    CMS_PNUMBER_POPUP_CONFIRM_CLICK: 'cms_pnumber_popup_confirm_click',
    CMS_PNUMBER_POPUP_CLOSE_CLICK: 'cms_pnumber_popup_close_click',
    SCREEN_OPEN_CMS_CARD_ADD: 'screen_open_cms_card_add',
    SCREEN_OPEN_CMS_CARD_EDITOR: 'screen_open_cms_card_editor',
    SCREEN_OPEN_CMS_CARD_CMS_FORM: 'screen_open_cms_card_cms_form',
    CMS_FORM_SAVE_CLICK: 'cms_form_save_click',
    CMS_FORM_CANCEL_CLICK: 'cms_form_cancel_click',
    SCREEN_OPEN_CMS_REQUEST_DETAIL: 'screen_open_cms_request_detail',
    CMS_REQUEST_DETAIL_CANCEL_CLICK: 'cms_request_detail_cancel_click',
    SCREEN_OPEN_CMS_REQUEST_DONE: 'screen_open_cms_request_done',
    CMS_REQUEST_DETAIL_SAVE_CLICK: 'cms_request_detail_save_click',
    SCREEN_OPEN_MY_CMS_REQUEST: 'screen_open_my_cms_request',
    SCREEN_OPEN_MY_CMS_REQUESTED: 'screen_open_my_cms_requested',
    SCREEN_OPEN_CANCEL_CMS_POPUP: 'screen_open_cancel_cms_popup',
    CMS_CANCEL_CMS_POPUP_CLOSE_CLICK: 'cms_cancel_cms_popup_close_click',
    CMS_CANCEL_CMS_POPUP_DETAIL_CLICK: 'cms_cancel_cms_popup_detail_click',
    CMS_CANCEL_CMS_POPUP_DETAIL_ETC_CLICK:
      'cms_cancel_cms_popup_detail_etc_click',
    SCREEN_OPEN_CMS_PAYMENT: 'screen_open_cms_payment',
    CMS_PAYMENT_PROMOTION_CLICK: 'cms_payment_promotion_click',
    CMS_PAYMENT_PAY_CLICK: 'cms_payment_pay_click',
    TOAST_PAYMENT_COMPLETED: 'toast_payment_completed',
    TOAST_PAYMENT_FAIL: 'toast_payment_fail',
    SCREEN_OPEN_CMS_REVIEW: 'screen_open_cms_review',
    CMS_REVIEW_DONE_CLICK: 'cms_review_done_click',
    CMS_REVIEW_EDIT_CLICK: 'cms_review_edit_click',
    SCREEN_OPEN_CMS_ESTIMATION: 'screen_open_cms_estimation',
    CMS_ESTIMATION_SEND_CLICK: 'cms_estimation_send_click',
    SCREEN_OPEN_CMS_ESTIMATION_DONE: 'screen_open_cms_estimation_done',
    SCREEN_OPEN_CMS_EARNINGS: 'screen_open_cms_earnings',
    SCREEN_OPEN_CMS_WITHDRAW: 'screen_open_cms_withdraw',
    CMS_WITHDRAW_ALL_CLICK: 'cms_withdraw_all_click',
    CMS_WITHDRAW_REQUEST_CLICK: 'cms_withdraw_request_click',
    CMS_WITHDRAW_PROMOTION_CLICK: 'cms_withdraw_promotion_click',
  },
};

export const FACEBOOK_ERROR_CODE = {
  NO_FACEBOOK_ACCESS_TOKEN: 'NO_FACEBOOK_ACCESS_TOKEN',
  NO_FACEBOOK_PAGE: 'NO_FACEBOOK_PAGE',
  NO_CONNECTED_INSTAGRAM: 'NO_CONNECTED_INSTAGRAM',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  WRONG_TOKEN: 'WRONG_TOKEN',
};
