import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type YourKemiQueryVariables = Types.Exact<{
  linkName: Types.Scalars['String'];
}>;

export type YourKemiQueryResponse = {
  kemi?: {
    id: string;
    linkName: string;
    profileImageUrl?: string | null;
    coverImageUrl?: string | null;
    title: string;
    description?: string | null;
    kemiType: Types.EKemiType;
    allCards: Array<
      | {
          __typename: 'CommissionCard';
          id: string;
          title: string;
          imageUrl?: string | null;
          imageSize?: Types.EImageSize | null;
          showImage: boolean;
          emoji?: string | null;
          showBadge: boolean;
          cardImageType?: Types.ECardImageType | null;
          badges?: Array<{
            title?: string | null;
            bgColor?: string | null;
          }> | null;
          commission?: { minRequestAmount: number } | null;
        }
      | {
          __typename: 'ImageCard';
          id: string;
          imageUrls: Array<string>;
          imageShape: Types.EImageShape;
          autoSlide: boolean;
          button?: {
            showButton: boolean;
            text?: string | null;
            bgColor?: string | null;
            textColor?: string | null;
          } | null;
          badge?: {
            showBadge: boolean;
            color?: string | null;
            text?: string | null;
          } | null;
          link?: { useLink: boolean; linkUrl?: string | null } | null;
          text?: {
            showText: boolean;
            header?: string | null;
            content?: string | null;
            color?: string | null;
          } | null;
        }
      | {
          __typename: 'LinkCard';
          id: string;
          title: string;
          url: string;
          showImage: boolean;
          cardImageType?: Types.ECardImageType | null;
          imageUrl?: string | null;
          imageSize?: Types.EImageSize | null;
          emoji?: string | null;
          updatedAt: string;
          showBadge: boolean;
          displayType?: Types.ECardDisplayType | null;
          displayScheduleState?: Types.ECardDisplayScheduleState | null;
          badges?: Array<{
            title?: string | null;
            bgColor?: string | null;
          }> | null;
        }
      | {
          __typename: 'MusicCard';
          id: string;
          title: string;
          musicUrl: string;
          showTitle: boolean;
          showBadge: boolean;
          thumbnailUrl: string;
          EMusicHostingService: Types.EMusicHostingService;
          badges?: Array<{
            bgColor?: string | null;
            title?: string | null;
          }> | null;
        }
      | {
          __typename: 'ProductCard';
          id: string;
          productId?: string | null;
          title: string;
          suggestedRetailPrice?: number | null;
          imageUrl?: string | null;
          imageSize?: Types.EImageSize | null;
          imageType?: Types.ECardImageType | null;
          emoji?: string | null;
          showImage: boolean;
          updatedAt: string;
          displayType?: Types.ECardDisplayType | null;
          displayScheduleState?: Types.ECardDisplayScheduleState | null;
          hiddenPrice: boolean;
          createdAt: string;
          showSalesSchedule: boolean;
          showBadge: boolean;
          layoutStyle?: Types.ELayoutStyle | null;
          productDetail?: {
            suggestedRetailPrice?: number | null;
            kemiSalesPeriodState?: Types.EProductKemiSalesPeriodState | null;
            kemiSalesStartAt?: string | null;
            kemiSalesEndAt?: string | null;
            productState?: Types.EProductState | null;
            discount?: {
              type: Types.EDiscountType;
              price?: number | null;
              rate?: number | null;
            } | null;
          } | null;
          badges?: Array<{
            title?: string | null;
            bgColor?: string | null;
          }> | null;
        }
      | {
          __typename: 'ScheduleCard';
          id: string;
          themeColor: string;
          showNumber: number;
          schedules: Array<{
            name: string;
            beginAt: string;
            endAt: string;
            isSetTime: boolean;
            link?: string | null;
            createdAt: string;
          }>;
        }
      | {
          __typename: 'TextCard';
          id: string;
          title: string;
          isBold: boolean;
          textAlign: Types.ETextAlign;
          margin?: Types.ETextMargin | null;
          isBig: boolean;
          updatedAt: string;
        }
      | {
          __typename: 'VideoCard';
          id: string;
          title: string;
          videoUrl: string;
          videoHostingService: Types.EVideoHostingService;
          showTitle: boolean;
          showBadge: boolean;
          mutePlay: boolean;
          autoPlay: boolean;
          thumbnailUrl: string;
          badges?: Array<{
            bgColor?: string | null;
            title?: string | null;
          }> | null;
        }
      | {}
    >;
    showingTree: Array<{ cardId: string }>;
    sns: Array<{ type: Types.ESnsType; link: string }>;
    representationImage?: {
      imageUrls: Array<string>;
      imageShape: Types.ERepresentationImageShape;
      autoSlide: boolean;
      useBannerTitle: boolean;
      bannerTitle?: string | null;
      link?: { useLink: boolean; linkUrl?: string | null } | null;
      text?: {
        showText: boolean;
        header?: string | null;
        content?: string | null;
        color?: string | null;
      } | null;
      button?: {
        showButton: boolean;
        text?: string | null;
        bgColor?: string | null;
        textColor?: string | null;
      } | null;
      badge?: {
        showBadge: boolean;
        color?: string | null;
        text?: string | null;
      } | null;
    } | null;
  } | null;
};

export const YourKemiDocument = `
    query YourKemi($linkName: String!) {
  kemi(linkName: $linkName) {
    id
    linkName
    profileImageUrl
    coverImageUrl
    title
    description
    kemiType
    allCards {
      ... on LinkCard {
        __typename
        id
        title
        url
        showImage
        cardImageType
        imageUrl
        imageSize
        emoji
        updatedAt
        showBadge
        displayType
        displayScheduleState
        badges {
          title
          bgColor
        }
      }
      ... on ProductCard {
        __typename
        id
        productId
        title
        suggestedRetailPrice
        imageUrl
        imageSize
        imageType
        emoji
        showImage
        updatedAt
        displayType
        displayScheduleState
        productDetail {
          suggestedRetailPrice
          discount {
            type
            price
            rate
          }
          kemiSalesPeriodState
          kemiSalesStartAt
          kemiSalesEndAt
          productState
        }
        hiddenPrice
        createdAt
        showSalesSchedule
        showBadge
        badges {
          title
          bgColor
        }
        layoutStyle
      }
      ... on TextCard {
        __typename
        id
        title
        isBold
        textAlign
        margin
        isBig
        updatedAt
      }
      ... on ImageCard {
        __typename
        id
        imageUrls
        imageShape
        autoSlide
        button {
          showButton
          text
          bgColor
          textColor
        }
        badge {
          showBadge
          color
          text
        }
        link {
          useLink
          linkUrl
        }
        text {
          showText
          header
          content
          color
        }
      }
      ... on VideoCard {
        __typename
        id
        title
        videoUrl
        videoHostingService
        showTitle
        showBadge
        badges {
          bgColor
          title
        }
        mutePlay
        autoPlay
        thumbnailUrl
      }
      ... on MusicCard {
        __typename
        id
        title
        musicUrl
        showTitle
        showBadge
        badges {
          bgColor
          title
        }
        thumbnailUrl
        EMusicHostingService
      }
      ... on CommissionCard {
        __typename
        id
        title
        imageUrl
        imageSize
        showImage
        emoji
        showBadge
        cardImageType
        badges {
          title
          bgColor
        }
        commission {
          minRequestAmount
        }
      }
      ... on ScheduleCard {
        __typename
        id
        themeColor
        showNumber
        schedules {
          name
          beginAt
          endAt
          isSetTime
          link
          createdAt
        }
      }
    }
    showingTree {
      cardId
    }
    sns {
      type
      link
    }
    representationImage {
      imageUrls
      imageShape
      autoSlide
      useBannerTitle
      bannerTitle
      link {
        useLink
        linkUrl
      }
      text {
        showText
        header
        content
        color
      }
      button {
        showButton
        text
        bgColor
        textColor
      }
      badge {
        showBadge
        color
        text
      }
    }
  }
}
    `;
export const useYourKemiQuery = <
  TData = YourKemiQueryResponse,
  TError = unknown
>(
  variables: YourKemiQueryVariables,
  options?: UseQueryOptions<YourKemiQueryResponse, TError, TData>
) =>
  useQuery<YourKemiQueryResponse, TError, TData>(
    ['YourKemi', variables],
    fetcher<YourKemiQueryResponse, YourKemiQueryVariables>(
      YourKemiDocument,
      variables
    ),
    options
  );

useYourKemiQuery.getKey = (variables: YourKemiQueryVariables) => [
  'YourKemi',
  variables,
];
export const useInfiniteYourKemiQuery = <
  TData = YourKemiQueryResponse,
  TError = unknown
>(
  variables: YourKemiQueryVariables,
  options?: UseInfiniteQueryOptions<YourKemiQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<YourKemiQueryResponse, TError, TData>(
    ['YourKemi.infinite', variables],
    (metaData) =>
      fetcher<YourKemiQueryResponse, YourKemiQueryVariables>(YourKemiDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteYourKemiQuery.getKey = (variables: YourKemiQueryVariables) => [
  'YourKemi.infinite',
  variables,
];
useYourKemiQuery.fetcher = (
  variables: YourKemiQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<YourKemiQueryResponse, YourKemiQueryVariables>(
    YourKemiDocument,
    variables,
    options
  );
