import { DefaultInput } from './shared/components/styled';
import { useRemoveValue } from './shared/hooks/useRemoveValue';
import { InputProps } from './shared/types';

import { styled } from '@styles/stitches.config';
import InputWrapper from 'src/design0.2/Inputs/shared/components/InputWrapper';

const Input = function ({
  label,
  caption,
  errorMessage,
  unit,
  suffix,
  hiddenClear,
  leftIcon,
  rightIcon,
  actionButton,
  showError,
  onRemove,
  prefixValue,
  ...props
}: InputProps) {
  const { ref, handleRemove } = useRemoveValue<HTMLInputElement>({
    onChange: props.onChange,
    onRemove,
  });

  return (
    <InputWrapper
      label={label}
      caption={caption}
      errorMessage={errorMessage}
      unit={unit}
      suffix={suffix}
      hiddenClear={hiddenClear}
      showError={showError}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      actionButton={actionButton}
      onRemove={handleRemove}
      id={props.id}
      disabled={props.disabled}
      required={props.required}
      value={props.value}
      prefixValue={prefixValue}
    >
      <StyledInput ref={ref} {...props} />
    </InputWrapper>
  );
};

const StyledInput = styled(DefaultInput, {
  border: 'none',
  margin: 0,
  padding: 0,
});

export default Input;
