import Image, { ImageProps } from 'next/image';
import { Get } from 'type-fest';

import { styled } from '@styles/stitches.config';

type SelectBoxProps = {
  emoji: Get<ImageProps, 'src'>;
  active: boolean;
  title: string;
  subTitle?: string;
};

function SelectBox({ emoji, active, title, subTitle }: SelectBoxProps) {
  return (
    <Container active={active}>
      <Emoji>
        <Image src={emoji} />
      </Emoji>
      <Content>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </Content>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  width: '100%',
  paddingX: 16,
  paddingY: 8,
  borderRadius: 16,
  border1: '$grey50',
  noTapHighlight: true,
  cursor: 'pointer',

  variants: {
    active: {
      true: {
        border1: '$secondary100',
        background: '$secondary10',
      },
    },
  },
});

const Emoji = styled('div', {
  flex: '0 0 64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Content = styled('div', {
  flex: 1,
});

const Title = styled('p', {
  fontType: 'subtitleRegular',
});

const SubTitle = styled('p', {
  fontType: 'labelRegular',
  color: '$grey100',
});

export default SelectBox;
