import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import OnboardingModal from './OnboardingModal';

import useUserQuery from '@global/queries/useUserQuery';
import ROUTE from '@routers';
import { OnboardingValues } from '@user/components/Onboarding/validator';
import { useUserTypeQuery } from '@user/graphql/Onboarding.generated';
import { flatMapDeepToString } from '@user/helpers/onboarding';
import { useOnboardingStatus } from '@user/hooks/useOnboardingStatus';
import { EOnboardingSteps } from '@user/types';

// 온보딩 모달을 띄우지 않는 페이지 라우트
// https://www.notion.so/wiredcompany/df2a3d01cf2045038cbba043208c88a6?pvs=4#efdd088566a64e13bf1496fd27d9fe3a
const whiteList = [
  ROUTE.index,
  ROUTE.debugMode, // 개발환경에서만 유효
  ROUTE.oAuthCallback, // 로그인 리디렉션 과정에서는 모달 오픈 하지 않음.
  /* 장바구니 페이지 */
  ...flatMapDeepToString(ROUTE.cart),
  /* 주문 페이지 */
  ...flatMapDeepToString(ROUTE.orders),
  /* 상품 페이지*/
  ...flatMapDeepToString(ROUTE.products),
];

function OnboardingContainer() {
  const router = useRouter();
  const { data: user } = useUserQuery();
  // login이 되어 있는 상태에만 온보딩 결과 데이터를 가져온다.
  const { data } = useUserTypeQuery(undefined, { enabled: !!user });
  const { onboardingStatus, isLoading, shouldOpenOnboarding, setEnabled } =
    useOnboardingStatus();

  const [isOpen, setIsOpen] = useState(false);

  const productToSellType = data?.me?.userType?.productToSellType || [];
  const snsUsageType = data?.me?.userType?.snsUsageType || '';
  const kemiUsageType = data?.me?.userType?.kemiUsageType || '';
  const isOldOnboardedUser = !!data?.me?.tempIsOldOnboardedUser;
  const initialStep: EOnboardingSteps =
    onboardingStatus.currentStep ||
    (isOldOnboardedUser ? 'kemiUsageType' : 'linkName');
  const linkName =
    isOldOnboardedUser || initialStep !== 'linkName'
      ? data?.me?.linkName || ''
      : '';

  const initialValues: OnboardingValues = {
    linkName,
    kemiUsageType,
    productToSellType,
    snsUsageType,
  };

  useEffect(() => {
    const isWhiteListRoute = whiteList.includes(router.route);

    if (!isLoading && shouldOpenOnboarding && !isWhiteListRoute) {
      setIsOpen(true);
    } else {
      setEnabled(false);
    }

    if (isWhiteListRoute) {
      setIsOpen(false);
    }
  }, [isLoading, shouldOpenOnboarding, router.route]);

  if (!isOpen || !data) {
    return null;
  }

  return (
    <OnboardingModal
      close={() => setIsOpen(false)}
      initialStep={initialStep}
      isOldOnboardedUser={isOldOnboardedUser}
      initialValues={initialValues}
    />
  );
}

export default OnboardingContainer;
