import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { useModalToast } from '@design0.2/Toast/hooks/useToast';
import { OnboardingValues } from '@user/components/Onboarding/validator';

export const useOnboardingErrorToast = () => {
  const { errors } = useFormikContext<OnboardingValues>();
  const { bad } = useModalToast();

  useEffect(() => {
    const message = Object.values(errors).find((error) => !!error);

    if (message && typeof message === 'string') {
      bad(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
};
