import {
  ChangeEvent,
  ChangeEventHandler,
  MouseEventHandler,
  useRef,
} from 'react';

type Elements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export const useRemoveValue = <T extends Elements>({
  onChange,
  onRemove,
}: {
  onChange?: ChangeEventHandler<T>;
  onRemove?: MouseEventHandler<HTMLButtonElement>;
}) => {
  const ref = useRef<T | null>(null);

  const handleRemove: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!ref.current) {
      return;
    }

    const currentTarget = ref.current.cloneNode(true) as T;
    const event = Object.create(e, {
      target: { value: currentTarget },
      currentTarget: { value: currentTarget },
    });

    currentTarget.value = '';
    onRemove?.(e);
    onChange?.(event as ChangeEvent<T>);
  };

  return { ref, handleRemove };
};
