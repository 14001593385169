import { styled } from '@styles/stitches.config';

type TipProps = {
  text: string;
};

function Tip({ text }: TipProps) {
  return (
    <Container>
      <Emoji>{'💡'}</Emoji>
      <Content>
        <p>{text}</p>
      </Content>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  width: '100%',
  paddingX: 16,
  borderRadius: 16,
  cursor: 'pointer',
  background: '$secondary20',
  minHeight: 80,
});

const Emoji = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 20,
});

const Content = styled('div', {
  flex: 1,
  fontType: 'subtitleRegular',
});

export default Tip;
