import { noop } from 'lodash';
import Image from 'next/image';
import { ButtonHTMLAttributes, ReactNode } from 'react';

import kemiLoader from '@static/media/kemi-fullpage-loader.gif';
import { styled } from '@styles/stitches.config';

type FloatingButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  buttonStyle?: 'primary' | 'secondary';
  loading?: boolean;
  // children 강제
  children: ReactNode;
};

const FloatingButton = (props: FloatingButtonProps) => {
  const {
    buttonStyle = 'primary',
    disabled = false,
    loading = false,
    onClick = noop,
    children,
    ...rest
  } = props;

  const clickable = !disabled && !loading;

  return (
    <FloatingButtonItself
      buttonStyle={buttonStyle}
      disabled={disabled}
      loading={loading}
      onClick={clickable ? onClick : undefined}
      {...rest}
    >
      {loading ? (
        <Image src={kemiLoader} width={88} height={16} alt={'loader'} />
      ) : (
        children
      )}
    </FloatingButtonItself>
  );
};

const FloatingButtonItself = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '18px 32px',
  backdropFilter: 'blur(32px)',
  fontType: 'subtitleBold',
  color: '$white',
  borderRadius: 28,
  cursor: 'pointer',

  '&:disabled': {
    cursor: 'unset',
  },

  variants: {
    buttonStyle: {
      primary: {
        transparentColor: 'translucentPrimary',

        '&:hover': {
          '@onlyHoverDevice': {
            backgroundColor: '$primary100',
          },
        },

        '&:disabled': {
          backgroundColor: '$primary50',
        },
      },

      secondary: {
        transparentColor: 'translucentBlack',

        '&:hover': {
          '@onlyHoverDevice': {
            backgroundColor: '$black',
          },
        },

        '&:disabled': {
          backgroundColor: '$grey50',
        },
      },
    },

    loading: {
      true: {},
    },
  },

  compoundVariants: [
    {
      buttonStyle: 'primary',
      loading: true,
      css: {
        backgroundColor: '$primary50',
        cursor: 'unset',

        '&:hover': {
          '@onlyHoverDevice': {
            backgroundColor: '$primary50',
          },
        },
      },
    },
    {
      buttonStyle: 'secondary',
      loading: true,
      css: {
        backgroundColor: '$grey50',
        cursor: 'unset',

        '&:hover': {
          '@onlyHoverDevice': {
            backgroundColor: '$grey50',
          },
        },
      },
    },
  ],

  defaultVariants: {
    buttonStyle: 'primary',
  },

  '&:hover': {
    cursor: 'pointer',
  },
});

export const FloatingContainer = styled('div', {
  position: 'sticky',
  bottom: 'calc(24px + env(safe-area-inset-bottom))',
  width: '100%',
  zIndex: '$floatingButton',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@mobile': {
    position: 'fixed',
    width: 'initial',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

export default FloatingButton;
