import { Player } from '@lottiefiles/react-lottie-player';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import OnboardingTitle from './OnboardingTitle';

import TextField from '@design0.2/Fields/TextField';
import { useModalToast } from '@design0.2/Toast/hooks/useToast';
import i18n from '@i18n/index';
import { LINK_NAME_MAX_LENGTH } from '@multilink/helper/setting';
import { styled } from '@styles/stitches.config';
import FredyHi from '@user/assets/lottie-fredyhi.json';
import { OnboardingValues } from '@user/components/Onboarding/validator';

type EditLinkNameProps = {
  errorMessage: string;
  resetLinkNameError: () => void;
};

function EditLinkName({ errorMessage, resetLinkNameError }: EditLinkNameProps) {
  const { handleChange, values, errors, isSubmitting, isValidating } =
    useFormikContext<OnboardingValues>();
  const { bad } = useModalToast();

  const hasError = !isEmpty(errors) || !!errorMessage;

  useEffect(() => {
    if (isSubmitting && !isValidating && !isEmpty(errors)) {
      bad(i18n.t('k_enter_required_field_plz'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isValidating, errors]);

  return (
    <div>
      <OnboardingTitle
        title1={i18n.t('k_register_done')}
        title2={i18n.t('k_start_kemi_right_now')}
        subTitle={i18n.t('k_not_a_long_time_ease')}
      />
      <Kemimoji>
        <Player autoplay loop src={FredyHi} />
      </Kemimoji>
      <Description>{i18n.t('k_plz_enter_linkname')}</Description>
      <TextField
        required
        name={'linkName'}
        placeholder={i18n.t('k_plz_enter_your_linkname')}
        prefixValue={'kemi.io/'}
        maxLength={LINK_NAME_MAX_LENGTH}
        suffix={
          values.linkName?.length
            ? `${values.linkName?.length} / ${LINK_NAME_MAX_LENGTH}`
            : undefined
        }
        autoCapitalize={'off'}
        label={i18n.t('k_kemi_link')}
        showError={hasError}
        errorMessage={errorMessage}
        caption={
          hasError
            ? ''
            : `${i18n.t('k_at_least_2_characters')}\n${i18n.t(
                'k_you_cannot_use_the_same_address_as_another_user'
              )}`
        }
        onChange={(e) => {
          e.target.value = e.target.value.trim().toLowerCase();
          handleChange(e);
          resetLinkNameError();
        }}
      />
    </div>
  );
}

const Kemimoji = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: 64,
});

const Description = styled('p', {
  paddingY: 16,
  fontType: 'subtitleBold',
  textAlign: 'center',
});

export default EditLinkName;
