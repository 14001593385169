import { styled } from '@styles/stitches.config';

type OnboardingTitleProps = {
  title1: string;
  title2?: string;
  subTitle?: string;
};

function OnboardingTitle({ title1, title2, subTitle }: OnboardingTitleProps) {
  return (
    <TitleWrapper>
      <Title>{title1}</Title>
      {title2 && <Title>{title2}</Title>}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </TitleWrapper>
  );
}

const TitleWrapper = styled('div', {
  padding: 16,
});

const Title = styled('h3', {
  fontType: 'heading3',
});

const SubTitle = styled('p', {
  fontType: 'bodyRegular',
  color: '$grey100',
  marginTop: 16,
});

export default OnboardingTitle;
