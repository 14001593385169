import Button from '@design0.2/Button/Button';
import SmallModal from '@design0.2/Modal/SmallModal';
import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';

type LaterAlertProps = {
  close: () => void;
  onConfirm: () => void;
  isLoading: boolean;
};

function LaterAlert({ close, onConfirm, isLoading }: LaterAlertProps) {
  return (
    <SmallModal
      title={`🚨 ${i18n.t('k_really_stop_processing')}`}
      onClose={close}
    >
      <Description>
        {`${i18n.t('k_if_you_stop_you_will_have_disadventage')} 🥺`}
      </Description>
      <Buttons>
        <Button type={'button'} onClick={close}>
          {i18n.t('k_keep_going')}
        </Button>
        <Button
          buttonStyle={'tertiary'}
          type={'button'}
          loading={isLoading}
          onClick={onConfirm}
        >
          {i18n.t('k_later')}
        </Button>
      </Buttons>
    </SmallModal>
  );
}

const Description = styled('p', {
  padding: 16,
  fontType: 'bodyRegular',
});

const Buttons = styled('div', {
  padding: 16,
  gridRowWithGap: 8,
});

export default LaterAlert;
