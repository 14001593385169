import { Field, useFormikContext } from 'formik';
import React from 'react';

import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';
import EmojiHeartEmpty from '@user/assets/emoji-heart-empty.svg';
import EmojiHeartFill from '@user/assets/emoji-heart-fill.svg';
import EmojiHeartHalf from '@user/assets/emoji-heart-half.svg';
import OnboardingTitle from '@user/components/Onboarding/OnboardingTitle';
import SelectBox from '@user/components/Onboarding/SelectBox';
import { OnboardingValues } from '@user/components/Onboarding/validator';
import { useOnboardingErrorToast } from '@user/hooks/useOnboardingErrorToast';

function SelectSnsUsageType() {
  const { values } = useFormikContext<OnboardingValues>();
  const snsUsageType = values.snsUsageType;

  useOnboardingErrorToast();

  return (
    <div>
      <OnboardingTitle
        title1={`${i18n.t('k_name_nim', { name: values.linkName })},`}
        title2={i18n.t('k_how_much_use_sns')}
        subTitle={i18n.t('k_more_effient_kemi_usage_sns')}
      />
      <Labels>
        <label>
          <SelectBox
            title={i18n.t('k_sns_heavy_user')}
            emoji={EmojiHeartFill}
            active={snsUsageType === 'HEAVY'}
          />
          <Radio type={'radio'} name={'snsUsageType'} value={'HEAVY'} />
        </label>
        <label>
          <SelectBox
            title={i18n.t('k_sns_casual_user')}
            emoji={EmojiHeartHalf}
            active={snsUsageType === 'CASUAL'}
          />
          <Radio type={'radio'} name={'snsUsageType'} value={'CASUAL'} />
        </label>
        <label>
          <SelectBox
            title={i18n.t('k_sns_light_user')}
            emoji={EmojiHeartEmpty}
            active={snsUsageType === 'LIGHT'}
          />
          <Radio type={'radio'} name={'snsUsageType'} value={'LIGHT'} />
        </label>
      </Labels>
    </div>
  );
}

const Labels = styled('div', {
  gridRowWithGap: 8,
  paddingY: 64,
});

const Radio = styled(Field, {
  display: 'none',
});

export default SelectSnsUsageType;
