import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type CommissionCardForDetailQueryVariables = Types.Exact<{
  cardId: Types.Scalars['String'];
}>;

export type CommissionCardForDetailQueryResponse = {
  card?:
    | {
        id: string;
        title: string;
        commissionId: string;
        createdAt: string;
        userId?: string | null;
        commission?: {
          id: string;
          createdAt: string;
          updatedAt: string;
          _userId: string;
          description?: string | null;
          imageUrls: Array<string>;
          minRequestAmount: number;
          maxWorkDay: number;
          maxNumberOfModification: number;
          detailInfo?: string | null;
          questions?: Array<{
            type: Types.ECommissionFormType;
            isRequired: boolean;
            questionTitle?: string | null;
            questionDescription?: string | null;
            isMultipleSelect?: boolean | null;
            selectableOptions?: Array<string> | null;
          }> | null;
        } | null;
        user?: { kemi: { id: string } } | null;
      }
    | {}
    | null;
};

export type CommissionInquiriesQueryVariables = Types.Exact<{
  input: Types.CommissionInquiriesInput;
}>;

export type CommissionInquiriesQueryResponse = {
  commissionInquiries?: {
    totalCount: number;
    inquiries: Array<{
      id: string;
      createdAt: string;
      updatedAt: string;
      creatorId: string;
      customerId: string;
      inquiry: string;
      isReplied: boolean;
      repliedAt?: string | null;
      reply?: string | null;
      creator?: {
        id: string;
        linkName: string;
        loginType?: Types.ELoginType | null;
        userProfile?: { name?: string | null } | null;
      } | null;
      customer?: {
        id: string;
        linkName: string;
        loginType?: Types.ELoginType | null;
        userProfile?: { name?: string | null } | null;
      } | null;
    }>;
  } | null;
};

export type CreateCommissionInquiryMutationVariables = Types.Exact<{
  detail: Types.CreateCommissionInquiryInput;
}>;

export type CreateCommissionInquiryMutationResponse = {
  createCommissionInquiry?: {
    inquiryId: string;
    record?: {
      id: string;
      createdAt: string;
      updatedAt: string;
      creatorId: string;
      customerId: string;
      inquiry: string;
      isReplied: boolean;
      repliedAt?: string | null;
      reply?: string | null;
      creator?: { id: string; linkName: string } | null;
      customer?: { id: string; linkName: string } | null;
    } | null;
  } | null;
};

export type UpdateCommissionInquiryMutationVariables = Types.Exact<{
  updateCommissionInquiryId: Types.Scalars['String'];
  detail: Types.UpdateCommissionInquiryInput;
}>;

export type UpdateCommissionInquiryMutationResponse = {
  updateCommissionInquiry?: {
    inquiryId: string;
    record?: {
      id: string;
      createdAt: string;
      updatedAt: string;
      creatorId: string;
      customerId: string;
      inquiry: string;
      isReplied: boolean;
      repliedAt?: string | null;
      reply?: string | null;
      creator?: { id: string; linkName: string } | null;
      customer?: { id: string; linkName: string } | null;
    } | null;
  } | null;
};

export type DeleteCommissionInquiryMutationVariables = Types.Exact<{
  deleteCommissionInquiryId: Types.Scalars['String'];
}>;

export type DeleteCommissionInquiryMutationResponse = {
  deleteCommissionInquiry?: { inquiryId: string } | null;
};

export type ReplyToCommissionInquiryMutationVariables = Types.Exact<{
  replyToCommissionInquiryId: Types.Scalars['String'];
  detail: Types.ReplyToCommissionInquiryInput;
}>;

export type ReplyToCommissionInquiryMutationResponse = {
  replyToCommissionInquiry?: {
    inquiryId: string;
    record?: {
      id: string;
      createdAt: string;
      updatedAt: string;
      creatorId: string;
      customerId: string;
      inquiry: string;
      isReplied: boolean;
      repliedAt?: string | null;
      reply?: string | null;
      creator?: { id: string; linkName: string } | null;
      customer?: { id: string; linkName: string } | null;
    } | null;
  } | null;
};

export type RemoveCommissionInquiryReplyMutationVariables = Types.Exact<{
  removeCommissionInquiryReplyId: Types.Scalars['String'];
}>;

export type RemoveCommissionInquiryReplyMutationResponse = {
  removeCommissionInquiryReply?: {
    inquiryId: string;
    record?: {
      id: string;
      createdAt: string;
      updatedAt: string;
      creatorId: string;
      customerId: string;
      inquiry: string;
      isReplied: boolean;
      repliedAt?: string | null;
      reply?: string | null;
      creator?: { id: string; linkName: string } | null;
      customer?: { id: string; linkName: string } | null;
    } | null;
  } | null;
};

export type CommissionReviewQueryVariables = Types.Exact<{
  commissionOrderId: Types.Scalars['String'];
}>;

export type CommissionReviewQueryResponse = {
  commissionReview?: {
    id: string;
    createdAt: string;
    updatedAt: string;
    customerId: string;
    comment: string;
    rating: number;
    customer?: {
      id: string;
      linkName: string;
      loginType?: Types.ELoginType | null;
      userProfile?: { name?: string | null } | null;
    } | null;
  } | null;
};

export type CommissionReviewsQueryVariables = Types.Exact<{
  input: Types.CommissionReviewsInput;
}>;

export type CommissionReviewsQueryResponse = {
  commissionReviews?: {
    totalCount: number;
    reviews: Array<{
      id: string;
      createdAt: string;
      updatedAt: string;
      customerId: string;
      comment: string;
      customer?: {
        id: string;
        linkName: string;
        loginType?: Types.ELoginType | null;
        userProfile?: { name?: string | null } | null;
      } | null;
    }>;
  } | null;
};

export type CreateCommissionReviewMutationVariables = Types.Exact<{
  commissionOrderId: Types.Scalars['String'];
  detail: Types.CreateCommissionReviewInput;
}>;

export type CreateCommissionReviewMutationResponse = {
  createCommissionReview?: { commissionReviewId: string } | null;
};

export type UpdateCommissionReviewMutationVariables = Types.Exact<{
  commissionReviewId: Types.Scalars['String'];
  detail: Types.UpdateCommissionReviewInput;
}>;

export type UpdateCommissionReviewMutationResponse = {
  updateCommissionReview?: { commissionReviewId: string } | null;
};

export const CommissionCardForDetailDocument = `
    query CommissionCardForDetail($cardId: String!) {
  card(id: $cardId) {
    ... on CommissionCard {
      id
      title
      commissionId
      commission {
        id
        createdAt
        updatedAt
        _userId
        description
        imageUrls
        minRequestAmount
        maxWorkDay
        maxNumberOfModification
        detailInfo
        questions {
          type
          isRequired
          questionTitle
          questionDescription
          isMultipleSelect
          selectableOptions
        }
      }
      createdAt
      userId
      user {
        kemi {
          id
        }
      }
    }
  }
}
    `;
export const useCommissionCardForDetailQuery = <
  TData = CommissionCardForDetailQueryResponse,
  TError = unknown
>(
  variables: CommissionCardForDetailQueryVariables,
  options?: UseQueryOptions<CommissionCardForDetailQueryResponse, TError, TData>
) =>
  useQuery<CommissionCardForDetailQueryResponse, TError, TData>(
    ['CommissionCardForDetail', variables],
    fetcher<
      CommissionCardForDetailQueryResponse,
      CommissionCardForDetailQueryVariables
    >(CommissionCardForDetailDocument, variables),
    options
  );

useCommissionCardForDetailQuery.getKey = (
  variables: CommissionCardForDetailQueryVariables
) => ['CommissionCardForDetail', variables];
export const useInfiniteCommissionCardForDetailQuery = <
  TData = CommissionCardForDetailQueryResponse,
  TError = unknown
>(
  variables: CommissionCardForDetailQueryVariables,
  options?: UseInfiniteQueryOptions<
    CommissionCardForDetailQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<CommissionCardForDetailQueryResponse, TError, TData>(
    ['CommissionCardForDetail.infinite', variables],
    (metaData) =>
      fetcher<
        CommissionCardForDetailQueryResponse,
        CommissionCardForDetailQueryVariables
      >(CommissionCardForDetailDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteCommissionCardForDetailQuery.getKey = (
  variables: CommissionCardForDetailQueryVariables
) => ['CommissionCardForDetail.infinite', variables];
useCommissionCardForDetailQuery.fetcher = (
  variables: CommissionCardForDetailQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    CommissionCardForDetailQueryResponse,
    CommissionCardForDetailQueryVariables
  >(CommissionCardForDetailDocument, variables, options);
export const CommissionInquiriesDocument = `
    query CommissionInquiries($input: CommissionInquiriesInput!) {
  commissionInquiries(input: $input) {
    inquiries {
      id
      createdAt
      updatedAt
      creatorId
      creator {
        id
        linkName
        loginType
        userProfile {
          name
        }
      }
      customerId
      customer {
        id
        linkName
        loginType
        userProfile {
          name
        }
      }
      inquiry
      isReplied
      repliedAt
      reply
    }
    totalCount
  }
}
    `;
export const useCommissionInquiriesQuery = <
  TData = CommissionInquiriesQueryResponse,
  TError = unknown
>(
  variables: CommissionInquiriesQueryVariables,
  options?: UseQueryOptions<CommissionInquiriesQueryResponse, TError, TData>
) =>
  useQuery<CommissionInquiriesQueryResponse, TError, TData>(
    ['CommissionInquiries', variables],
    fetcher<
      CommissionInquiriesQueryResponse,
      CommissionInquiriesQueryVariables
    >(CommissionInquiriesDocument, variables),
    options
  );

useCommissionInquiriesQuery.getKey = (
  variables: CommissionInquiriesQueryVariables
) => ['CommissionInquiries', variables];
export const useInfiniteCommissionInquiriesQuery = <
  TData = CommissionInquiriesQueryResponse,
  TError = unknown
>(
  variables: CommissionInquiriesQueryVariables,
  options?: UseInfiniteQueryOptions<
    CommissionInquiriesQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<CommissionInquiriesQueryResponse, TError, TData>(
    ['CommissionInquiries.infinite', variables],
    (metaData) =>
      fetcher<
        CommissionInquiriesQueryResponse,
        CommissionInquiriesQueryVariables
      >(CommissionInquiriesDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteCommissionInquiriesQuery.getKey = (
  variables: CommissionInquiriesQueryVariables
) => ['CommissionInquiries.infinite', variables];
useCommissionInquiriesQuery.fetcher = (
  variables: CommissionInquiriesQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<CommissionInquiriesQueryResponse, CommissionInquiriesQueryVariables>(
    CommissionInquiriesDocument,
    variables,
    options
  );
export const CreateCommissionInquiryDocument = `
    mutation CreateCommissionInquiry($detail: CreateCommissionInquiryInput!) {
  createCommissionInquiry(detail: $detail) {
    inquiryId
    record {
      id
      createdAt
      updatedAt
      creatorId
      creator {
        id
        linkName
      }
      customerId
      customer {
        id
        linkName
      }
      inquiry
      isReplied
      repliedAt
      reply
    }
  }
}
    `;
export const useCreateCommissionInquiryMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateCommissionInquiryMutationResponse,
    TError,
    CreateCommissionInquiryMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateCommissionInquiryMutationResponse,
    TError,
    CreateCommissionInquiryMutationVariables,
    TContext
  >(
    ['CreateCommissionInquiry'],
    (variables?: CreateCommissionInquiryMutationVariables) =>
      fetcher<
        CreateCommissionInquiryMutationResponse,
        CreateCommissionInquiryMutationVariables
      >(CreateCommissionInquiryDocument, variables)(),
    options
  );
useCreateCommissionInquiryMutation.getKey = () => ['CreateCommissionInquiry'];

useCreateCommissionInquiryMutation.fetcher = (
  variables: CreateCommissionInquiryMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    CreateCommissionInquiryMutationResponse,
    CreateCommissionInquiryMutationVariables
  >(CreateCommissionInquiryDocument, variables, options);
export const UpdateCommissionInquiryDocument = `
    mutation UpdateCommissionInquiry($updateCommissionInquiryId: String!, $detail: UpdateCommissionInquiryInput!) {
  updateCommissionInquiry(id: $updateCommissionInquiryId, detail: $detail) {
    inquiryId
    record {
      id
      createdAt
      updatedAt
      creatorId
      creator {
        id
        linkName
      }
      customerId
      customer {
        id
        linkName
      }
      inquiry
      isReplied
      repliedAt
      reply
    }
  }
}
    `;
export const useUpdateCommissionInquiryMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateCommissionInquiryMutationResponse,
    TError,
    UpdateCommissionInquiryMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateCommissionInquiryMutationResponse,
    TError,
    UpdateCommissionInquiryMutationVariables,
    TContext
  >(
    ['UpdateCommissionInquiry'],
    (variables?: UpdateCommissionInquiryMutationVariables) =>
      fetcher<
        UpdateCommissionInquiryMutationResponse,
        UpdateCommissionInquiryMutationVariables
      >(UpdateCommissionInquiryDocument, variables)(),
    options
  );
useUpdateCommissionInquiryMutation.getKey = () => ['UpdateCommissionInquiry'];

useUpdateCommissionInquiryMutation.fetcher = (
  variables: UpdateCommissionInquiryMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    UpdateCommissionInquiryMutationResponse,
    UpdateCommissionInquiryMutationVariables
  >(UpdateCommissionInquiryDocument, variables, options);
export const DeleteCommissionInquiryDocument = `
    mutation DeleteCommissionInquiry($deleteCommissionInquiryId: String!) {
  deleteCommissionInquiry(id: $deleteCommissionInquiryId) {
    inquiryId
  }
}
    `;
export const useDeleteCommissionInquiryMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteCommissionInquiryMutationResponse,
    TError,
    DeleteCommissionInquiryMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteCommissionInquiryMutationResponse,
    TError,
    DeleteCommissionInquiryMutationVariables,
    TContext
  >(
    ['DeleteCommissionInquiry'],
    (variables?: DeleteCommissionInquiryMutationVariables) =>
      fetcher<
        DeleteCommissionInquiryMutationResponse,
        DeleteCommissionInquiryMutationVariables
      >(DeleteCommissionInquiryDocument, variables)(),
    options
  );
useDeleteCommissionInquiryMutation.getKey = () => ['DeleteCommissionInquiry'];

useDeleteCommissionInquiryMutation.fetcher = (
  variables: DeleteCommissionInquiryMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    DeleteCommissionInquiryMutationResponse,
    DeleteCommissionInquiryMutationVariables
  >(DeleteCommissionInquiryDocument, variables, options);
export const ReplyToCommissionInquiryDocument = `
    mutation ReplyToCommissionInquiry($replyToCommissionInquiryId: String!, $detail: ReplyToCommissionInquiryInput!) {
  replyToCommissionInquiry(id: $replyToCommissionInquiryId, detail: $detail) {
    inquiryId
    record {
      id
      createdAt
      updatedAt
      creatorId
      creator {
        id
        linkName
      }
      customerId
      customer {
        id
        linkName
      }
      inquiry
      isReplied
      repliedAt
      reply
    }
  }
}
    `;
export const useReplyToCommissionInquiryMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ReplyToCommissionInquiryMutationResponse,
    TError,
    ReplyToCommissionInquiryMutationVariables,
    TContext
  >
) =>
  useMutation<
    ReplyToCommissionInquiryMutationResponse,
    TError,
    ReplyToCommissionInquiryMutationVariables,
    TContext
  >(
    ['ReplyToCommissionInquiry'],
    (variables?: ReplyToCommissionInquiryMutationVariables) =>
      fetcher<
        ReplyToCommissionInquiryMutationResponse,
        ReplyToCommissionInquiryMutationVariables
      >(ReplyToCommissionInquiryDocument, variables)(),
    options
  );
useReplyToCommissionInquiryMutation.getKey = () => ['ReplyToCommissionInquiry'];

useReplyToCommissionInquiryMutation.fetcher = (
  variables: ReplyToCommissionInquiryMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    ReplyToCommissionInquiryMutationResponse,
    ReplyToCommissionInquiryMutationVariables
  >(ReplyToCommissionInquiryDocument, variables, options);
export const RemoveCommissionInquiryReplyDocument = `
    mutation RemoveCommissionInquiryReply($removeCommissionInquiryReplyId: String!) {
  removeCommissionInquiryReply(id: $removeCommissionInquiryReplyId) {
    inquiryId
    record {
      id
      createdAt
      updatedAt
      creatorId
      creator {
        id
        linkName
      }
      customerId
      customer {
        id
        linkName
      }
      inquiry
      isReplied
      repliedAt
      reply
    }
  }
}
    `;
export const useRemoveCommissionInquiryReplyMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RemoveCommissionInquiryReplyMutationResponse,
    TError,
    RemoveCommissionInquiryReplyMutationVariables,
    TContext
  >
) =>
  useMutation<
    RemoveCommissionInquiryReplyMutationResponse,
    TError,
    RemoveCommissionInquiryReplyMutationVariables,
    TContext
  >(
    ['RemoveCommissionInquiryReply'],
    (variables?: RemoveCommissionInquiryReplyMutationVariables) =>
      fetcher<
        RemoveCommissionInquiryReplyMutationResponse,
        RemoveCommissionInquiryReplyMutationVariables
      >(RemoveCommissionInquiryReplyDocument, variables)(),
    options
  );
useRemoveCommissionInquiryReplyMutation.getKey = () => [
  'RemoveCommissionInquiryReply',
];

useRemoveCommissionInquiryReplyMutation.fetcher = (
  variables: RemoveCommissionInquiryReplyMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    RemoveCommissionInquiryReplyMutationResponse,
    RemoveCommissionInquiryReplyMutationVariables
  >(RemoveCommissionInquiryReplyDocument, variables, options);
export const CommissionReviewDocument = `
    query CommissionReview($commissionOrderId: String!) {
  commissionReview(commissionOrderId: $commissionOrderId) {
    id
    createdAt
    updatedAt
    customerId
    customer {
      id
      linkName
      loginType
      userProfile {
        name
      }
    }
    comment
    rating
  }
}
    `;
export const useCommissionReviewQuery = <
  TData = CommissionReviewQueryResponse,
  TError = unknown
>(
  variables: CommissionReviewQueryVariables,
  options?: UseQueryOptions<CommissionReviewQueryResponse, TError, TData>
) =>
  useQuery<CommissionReviewQueryResponse, TError, TData>(
    ['CommissionReview', variables],
    fetcher<CommissionReviewQueryResponse, CommissionReviewQueryVariables>(
      CommissionReviewDocument,
      variables
    ),
    options
  );

useCommissionReviewQuery.getKey = (
  variables: CommissionReviewQueryVariables
) => ['CommissionReview', variables];
export const useInfiniteCommissionReviewQuery = <
  TData = CommissionReviewQueryResponse,
  TError = unknown
>(
  variables: CommissionReviewQueryVariables,
  options?: UseInfiniteQueryOptions<
    CommissionReviewQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<CommissionReviewQueryResponse, TError, TData>(
    ['CommissionReview.infinite', variables],
    (metaData) =>
      fetcher<CommissionReviewQueryResponse, CommissionReviewQueryVariables>(
        CommissionReviewDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteCommissionReviewQuery.getKey = (
  variables: CommissionReviewQueryVariables
) => ['CommissionReview.infinite', variables];
useCommissionReviewQuery.fetcher = (
  variables: CommissionReviewQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<CommissionReviewQueryResponse, CommissionReviewQueryVariables>(
    CommissionReviewDocument,
    variables,
    options
  );
export const CommissionReviewsDocument = `
    query CommissionReviews($input: CommissionReviewsInput!) {
  commissionReviews(input: $input) {
    reviews {
      id
      createdAt
      updatedAt
      customerId
      customer {
        id
        linkName
        loginType
        userProfile {
          name
        }
      }
      comment
    }
    totalCount
  }
}
    `;
export const useCommissionReviewsQuery = <
  TData = CommissionReviewsQueryResponse,
  TError = unknown
>(
  variables: CommissionReviewsQueryVariables,
  options?: UseQueryOptions<CommissionReviewsQueryResponse, TError, TData>
) =>
  useQuery<CommissionReviewsQueryResponse, TError, TData>(
    ['CommissionReviews', variables],
    fetcher<CommissionReviewsQueryResponse, CommissionReviewsQueryVariables>(
      CommissionReviewsDocument,
      variables
    ),
    options
  );

useCommissionReviewsQuery.getKey = (
  variables: CommissionReviewsQueryVariables
) => ['CommissionReviews', variables];
export const useInfiniteCommissionReviewsQuery = <
  TData = CommissionReviewsQueryResponse,
  TError = unknown
>(
  variables: CommissionReviewsQueryVariables,
  options?: UseInfiniteQueryOptions<
    CommissionReviewsQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<CommissionReviewsQueryResponse, TError, TData>(
    ['CommissionReviews.infinite', variables],
    (metaData) =>
      fetcher<CommissionReviewsQueryResponse, CommissionReviewsQueryVariables>(
        CommissionReviewsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteCommissionReviewsQuery.getKey = (
  variables: CommissionReviewsQueryVariables
) => ['CommissionReviews.infinite', variables];
useCommissionReviewsQuery.fetcher = (
  variables: CommissionReviewsQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<CommissionReviewsQueryResponse, CommissionReviewsQueryVariables>(
    CommissionReviewsDocument,
    variables,
    options
  );
export const CreateCommissionReviewDocument = `
    mutation CreateCommissionReview($commissionOrderId: String!, $detail: CreateCommissionReviewInput!) {
  createCommissionReview(commissionOrderId: $commissionOrderId, detail: $detail) {
    commissionReviewId
  }
}
    `;
export const useCreateCommissionReviewMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateCommissionReviewMutationResponse,
    TError,
    CreateCommissionReviewMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateCommissionReviewMutationResponse,
    TError,
    CreateCommissionReviewMutationVariables,
    TContext
  >(
    ['CreateCommissionReview'],
    (variables?: CreateCommissionReviewMutationVariables) =>
      fetcher<
        CreateCommissionReviewMutationResponse,
        CreateCommissionReviewMutationVariables
      >(CreateCommissionReviewDocument, variables)(),
    options
  );
useCreateCommissionReviewMutation.getKey = () => ['CreateCommissionReview'];

useCreateCommissionReviewMutation.fetcher = (
  variables: CreateCommissionReviewMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    CreateCommissionReviewMutationResponse,
    CreateCommissionReviewMutationVariables
  >(CreateCommissionReviewDocument, variables, options);
export const UpdateCommissionReviewDocument = `
    mutation UpdateCommissionReview($commissionReviewId: String!, $detail: UpdateCommissionReviewInput!) {
  updateCommissionReview(commissionReviewId: $commissionReviewId, detail: $detail) {
    commissionReviewId
  }
}
    `;
export const useUpdateCommissionReviewMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateCommissionReviewMutationResponse,
    TError,
    UpdateCommissionReviewMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateCommissionReviewMutationResponse,
    TError,
    UpdateCommissionReviewMutationVariables,
    TContext
  >(
    ['UpdateCommissionReview'],
    (variables?: UpdateCommissionReviewMutationVariables) =>
      fetcher<
        UpdateCommissionReviewMutationResponse,
        UpdateCommissionReviewMutationVariables
      >(UpdateCommissionReviewDocument, variables)(),
    options
  );
useUpdateCommissionReviewMutation.getKey = () => ['UpdateCommissionReview'];

useUpdateCommissionReviewMutation.fetcher = (
  variables: UpdateCommissionReviewMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    UpdateCommissionReviewMutationResponse,
    UpdateCommissionReviewMutationVariables
  >(UpdateCommissionReviewDocument, variables, options);
