import { array, object, string } from 'yup';

import i18n from '@i18n/index';
import { linkNameSchema } from '@multilink/helper/validator';
import {
  EKemiUsageType,
  EProductToSellType,
  ESnsUsageType,
} from '@schema/types';
import { EOnboardingSteps } from '@user/types';

export type OnboardingValues = {
  linkName: string;
  kemiUsageType: EKemiUsageType | '';
  productToSellType: EProductToSellType[];
  snsUsageType: ESnsUsageType | '';
};

export const onboardingFormSchema = (step: EOnboardingSteps) => {
  switch (step) {
    case 'linkName':
      return object({
        linkName: linkNameSchema,
      });

    case 'kemiUsageType':
      return object({
        kemiUsageType: string().required(i18n.t('k_select_type')),
      });
    case 'snsUsageType':
      return object({
        snsUsageType: string().required(i18n.t('k_select_type')),
      });
    case 'productToSellType':
      return object({
        productToSellType: array()
          .of(string().required(i18n.t('k_select_type')))
          .min(1, i18n.t('k_select_type')),
      });

    default:
      return object();
  }
};
