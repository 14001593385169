import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import useUserQuery from '@global/queries/useUserQuery';
import {
  useOnboardingStatusQuery,
  useUpdateOnboardingStatusMutation,
} from '@user/graphql/Onboarding.generated';
import { EOnboardingSteps } from '@user/types';

const KEY = 'onboarding-status';
const OPENED_LIMIT = 5;

type OnboardingStatus = {
  opened: number;
  lastOpenedAt: string;
  currentStep?: EOnboardingSteps;
  isCompleted: boolean;
};

export const useOnboardingStatus = () => {
  const { data: user } = useUserQuery();
  const [enabled, setEnabled] = useState(true);

  const { data, isLoading } = useOnboardingStatusQuery(undefined, {
    enabled: enabled && !!user,
  });
  const { mutateAsync } = useUpdateOnboardingStatusMutation();

  const onboardingStatus = useMemo(() => {
    try {
      const statusJson = data?.properties.find(
        ({ name }) => name === KEY
      )?.value;

      if (!statusJson) {
        return {
          opened: 0,
          lastOpenedAt: '',
          isCompleted: false,
        };
      }

      const status = JSON.parse(statusJson) as OnboardingStatus;

      return status;
    } catch (e) {
      return {
        opened: 0,
        lastOpenedAt: '',
        isCompleted: false,
      };
    }
  }, [data]);

  const shouldOpenOnboarding = useMemo(() => {
    const { lastOpenedAt, opened, isCompleted } = onboardingStatus;

    // 온보딩 완료 처리가 된 경우
    if (isCompleted) {
      return false;
    }

    // 오늘 온보딩 화면에서 이탈한 경우
    if (dayjs(lastOpenedAt).isToday()) {
      return false;
    }

    // 최대 온보딩 이탈 횟수(5회) 이상일 경우
    if (opened >= OPENED_LIMIT) {
      return false;
    }

    return true;
  }, [onboardingStatus]);

  const completeOnboarding = async (completed?: boolean) => {
    const opened = onboardingStatus.opened + 1;
    const isCompleted = opened >= OPENED_LIMIT || completed;

    const status = JSON.stringify({
      ...onboardingStatus,
      opened: onboardingStatus.opened + 1,
      lastOpenedAt: dayjs().toISOString(),
      isCompleted,
    });

    await mutateAsync({ name: KEY, value: status });
  };

  const updateOnboardingStep = async (currentStep?: EOnboardingSteps) => {
    const status = JSON.stringify({
      ...onboardingStatus,
      currentStep,
    });

    await mutateAsync({ name: KEY, value: status });
  };

  // debug-mode에서만 사용한다.
  const clearOnboardingStatus = async () => {
    const status = JSON.stringify({
      opened: 0,
      lastOpenedAt: '',
    });

    await mutateAsync({ name: KEY, value: status });
  };

  return {
    onboardingStatus,
    shouldOpenOnboarding,
    clearOnboardingStatus,
    updateOnboardingStep,
    completeOnboarding,
    isLoading,
    setEnabled,
  };
};
