import * as Types from 'schema/types';

import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { fetcher } from '@global/network';
export type UserTypeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserTypeQueryResponse = {
  me?: {
    tempIsOldOnboardedUser?: boolean | null;
    linkName: string;
    userType?: {
      kemiUsageType?: Types.EKemiUsageType | null;
      productToSellType?: Array<Types.EProductToSellType> | null;
      snsUsageType?: Types.ESnsUsageType | null;
    } | null;
  } | null;
};

export type UpdateKemiProfileMutationVariables = Types.Exact<{
  kemiId: Types.Scalars['String'];
  profile: Types.UpdateKemiProfileInput;
}>;

export type UpdateKemiProfileMutationResponse = {
  updateKemiProfile?: { kemiId: string } | null;
};

export type UpdateProfileMutationVariables = Types.Exact<{
  userType?: Types.InputMaybe<Types.UserTypeInput>;
}>;

export type UpdateProfileMutationResponse = {
  updateProfile?: { userId: string } | null;
};

export type OnboardingStatusQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type OnboardingStatusQueryResponse = {
  properties: Array<{ name: string; value: string }>;
};

export type UpdateOnboardingStatusMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  value: Types.Scalars['String'];
}>;

export type UpdateOnboardingStatusMutationResponse = {
  setProperty?: { success: boolean } | null;
};

export const UserTypeDocument = `
    query UserType {
  me {
    userType {
      kemiUsageType
      productToSellType
      snsUsageType
    }
    tempIsOldOnboardedUser
    linkName
  }
}
    `;
export const useUserTypeQuery = <
  TData = UserTypeQueryResponse,
  TError = unknown
>(
  variables?: UserTypeQueryVariables,
  options?: UseQueryOptions<UserTypeQueryResponse, TError, TData>
) =>
  useQuery<UserTypeQueryResponse, TError, TData>(
    variables === undefined ? ['UserType'] : ['UserType', variables],
    fetcher<UserTypeQueryResponse, UserTypeQueryVariables>(
      UserTypeDocument,
      variables
    ),
    options
  );

useUserTypeQuery.getKey = (variables?: UserTypeQueryVariables) =>
  variables === undefined ? ['UserType'] : ['UserType', variables];
export const useInfiniteUserTypeQuery = <
  TData = UserTypeQueryResponse,
  TError = unknown
>(
  variables?: UserTypeQueryVariables,
  options?: UseInfiniteQueryOptions<UserTypeQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<UserTypeQueryResponse, TError, TData>(
    variables === undefined
      ? ['UserType.infinite']
      : ['UserType.infinite', variables],
    (metaData) =>
      fetcher<UserTypeQueryResponse, UserTypeQueryVariables>(UserTypeDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteUserTypeQuery.getKey = (variables?: UserTypeQueryVariables) =>
  variables === undefined
    ? ['UserType.infinite']
    : ['UserType.infinite', variables];
useUserTypeQuery.fetcher = (
  variables?: UserTypeQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<UserTypeQueryResponse, UserTypeQueryVariables>(
    UserTypeDocument,
    variables,
    options
  );
export const UpdateKemiProfileDocument = `
    mutation UpdateKemiProfile($kemiId: String!, $profile: UpdateKemiProfileInput!) {
  updateKemiProfile(kemiId: $kemiId, profile: $profile) {
    kemiId
  }
}
    `;
export const useUpdateKemiProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateKemiProfileMutationResponse,
    TError,
    UpdateKemiProfileMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateKemiProfileMutationResponse,
    TError,
    UpdateKemiProfileMutationVariables,
    TContext
  >(
    ['UpdateKemiProfile'],
    (variables?: UpdateKemiProfileMutationVariables) =>
      fetcher<
        UpdateKemiProfileMutationResponse,
        UpdateKemiProfileMutationVariables
      >(UpdateKemiProfileDocument, variables)(),
    options
  );
useUpdateKemiProfileMutation.getKey = () => ['UpdateKemiProfile'];

useUpdateKemiProfileMutation.fetcher = (
  variables: UpdateKemiProfileMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    UpdateKemiProfileMutationResponse,
    UpdateKemiProfileMutationVariables
  >(UpdateKemiProfileDocument, variables, options);
export const UpdateProfileDocument = `
    mutation UpdateProfile($userType: UserTypeInput) {
  updateProfile(userType: $userType) {
    userId
  }
}
    `;
export const useUpdateProfileMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateProfileMutationResponse,
    TError,
    UpdateProfileMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateProfileMutationResponse,
    TError,
    UpdateProfileMutationVariables,
    TContext
  >(
    ['UpdateProfile'],
    (variables?: UpdateProfileMutationVariables) =>
      fetcher<UpdateProfileMutationResponse, UpdateProfileMutationVariables>(
        UpdateProfileDocument,
        variables
      )(),
    options
  );
useUpdateProfileMutation.getKey = () => ['UpdateProfile'];

useUpdateProfileMutation.fetcher = (
  variables?: UpdateProfileMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<UpdateProfileMutationResponse, UpdateProfileMutationVariables>(
    UpdateProfileDocument,
    variables,
    options
  );
export const OnboardingStatusDocument = `
    query OnboardingStatus {
  properties {
    name
    value
  }
}
    `;
export const useOnboardingStatusQuery = <
  TData = OnboardingStatusQueryResponse,
  TError = unknown
>(
  variables?: OnboardingStatusQueryVariables,
  options?: UseQueryOptions<OnboardingStatusQueryResponse, TError, TData>
) =>
  useQuery<OnboardingStatusQueryResponse, TError, TData>(
    variables === undefined
      ? ['OnboardingStatus']
      : ['OnboardingStatus', variables],
    fetcher<OnboardingStatusQueryResponse, OnboardingStatusQueryVariables>(
      OnboardingStatusDocument,
      variables
    ),
    options
  );

useOnboardingStatusQuery.getKey = (
  variables?: OnboardingStatusQueryVariables
) =>
  variables === undefined
    ? ['OnboardingStatus']
    : ['OnboardingStatus', variables];
export const useInfiniteOnboardingStatusQuery = <
  TData = OnboardingStatusQueryResponse,
  TError = unknown
>(
  variables?: OnboardingStatusQueryVariables,
  options?: UseInfiniteQueryOptions<
    OnboardingStatusQueryResponse,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<OnboardingStatusQueryResponse, TError, TData>(
    variables === undefined
      ? ['OnboardingStatus.infinite']
      : ['OnboardingStatus.infinite', variables],
    (metaData) =>
      fetcher<OnboardingStatusQueryResponse, OnboardingStatusQueryVariables>(
        OnboardingStatusDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteOnboardingStatusQuery.getKey = (
  variables?: OnboardingStatusQueryVariables
) =>
  variables === undefined
    ? ['OnboardingStatus.infinite']
    : ['OnboardingStatus.infinite', variables];
useOnboardingStatusQuery.fetcher = (
  variables?: OnboardingStatusQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<OnboardingStatusQueryResponse, OnboardingStatusQueryVariables>(
    OnboardingStatusDocument,
    variables,
    options
  );
export const UpdateOnboardingStatusDocument = `
    mutation UpdateOnboardingStatus($name: String!, $value: String!) {
  setProperty(name: $name, value: $value) {
    success
  }
}
    `;
export const useUpdateOnboardingStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateOnboardingStatusMutationResponse,
    TError,
    UpdateOnboardingStatusMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateOnboardingStatusMutationResponse,
    TError,
    UpdateOnboardingStatusMutationVariables,
    TContext
  >(
    ['UpdateOnboardingStatus'],
    (variables?: UpdateOnboardingStatusMutationVariables) =>
      fetcher<
        UpdateOnboardingStatusMutationResponse,
        UpdateOnboardingStatusMutationVariables
      >(UpdateOnboardingStatusDocument, variables)(),
    options
  );
useUpdateOnboardingStatusMutation.getKey = () => ['UpdateOnboardingStatus'];

useUpdateOnboardingStatusMutation.fetcher = (
  variables: UpdateOnboardingStatusMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    UpdateOnboardingStatusMutationResponse,
    UpdateOnboardingStatusMutationVariables
  >(UpdateOnboardingStatusDocument, variables, options);
