import { Field, useFormikContext } from 'formik';

import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';
import EmojiCompany from '@user/assets/emoji-company.svg';
import EmojiYellowBox from '@user/assets/emoji-yellow-box.svg';
import KemimojiShaSha2 from '@user/assets/kemimoji-shasha2.svg';
import OnboardingTitle from '@user/components/Onboarding/OnboardingTitle';
import SelectBox from '@user/components/Onboarding/SelectBox';
import { OnboardingValues } from '@user/components/Onboarding/validator';
import { useOnboardingErrorToast } from '@user/hooks/useOnboardingErrorToast';

function SelectProductToSellType() {
  const { values } = useFormikContext<OnboardingValues>();
  const productToSellType = values.productToSellType;

  useOnboardingErrorToast();

  return (
    <div>
      <OnboardingTitle
        title1={`${i18n.t('k_good_nim', { name: values.linkName })},`}
        title2={i18n.t('k_onboarding_select_seller_type_title')}
        subTitle={i18n.t('k_select_all_you_want')}
      />
      <Labels>
        <label>
          <SelectBox
            title={i18n.t('k_self_supply_product')}
            emoji={EmojiYellowBox}
            active={productToSellType.includes('SELF')}
          />
          <CheckBox
            type={'checkbox'}
            name={'productToSellType'}
            value={'SELF'}
          />
        </label>
        <label>
          <SelectBox
            title={i18n.t('k_products_by_supplier')}
            emoji={EmojiCompany}
            active={productToSellType.includes('MARKET')}
          />
          <CheckBox
            type={'checkbox'}
            name={'productToSellType'}
            value={'MARKET'}
          />
        </label>
        <label>
          <SelectBox
            title={i18n.t('k_would_you_recommend_products')}
            emoji={KemimojiShaSha2}
            active={productToSellType.includes('KEMI')}
          />
          <CheckBox
            type={'checkbox'}
            name={'productToSellType'}
            value={'KEMI'}
          />
        </label>
      </Labels>
    </div>
  );
}

const Labels = styled('div', {
  gridRowWithGap: 8,
  paddingY: 64,
});

const CheckBox = styled(Field, {
  display: 'none',
});

export default SelectProductToSellType;
