import dayjs from 'dayjs';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import * as analytics from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

import { User } from '@global/queries/useUserQuery';
import { UserInteractionLogParam } from '@global/service/logger/event-logger/index';
import { IEventLogger } from '@global/service/logger/interfaces';

type FirebaseConfigType = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

interface IFirebaseLogger {
  setUser: (user: User | null) => void;
  init: () => void;
}

class FirebaseLogger
  implements IEventLogger<UserInteractionLogParam>, IFirebaseLogger
{
  protected firebaseConfig: FirebaseConfigType;
  protected analytics: analytics.Analytics | null;

  constructor() {
    this.firebaseConfig = {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY as string,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN as string,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET as string,
      messagingSenderId: process.env
        .NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID as string,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID as string,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID as string,
    };
    this.analytics = null;
    this.init();
  }

  log({ firebase }: UserInteractionLogParam) {
    if (!firebase || !this.analytics) {
      return;
    }

    const eventType = firebase.logName;
    const eventOption = firebase.logOption;
    logEvent(this.analytics, eventType, eventOption);
  }

  setUser(user: User | null) {
    /**
     * 로그아웃은 신경쓰지 않는다. (user가 Null일 때)
     * 로그아웃 후 재로그인하여 User가 변경될 경우 다시 set 해준다.
     */
    if (!this.analytics || !user) {
      return;
    }

    const userInfo = {
      user_id: user.userId,
      is_seller: user.sellerInfo.isSeller,
      seller_grade: user.sellerInfo.grade,
      is_insta_seller: user.sellerInfo.isInstagramSeller,
      created_at: dayjs(user.createdAt).format('YYYYMMDD'),
      name: user.profile.name,
      gender: user.profile.gender,
      year: user.profile.birth?.substring(0, 4),
      kemiUsageType: user.userType?.kemiUsageType,
      productToSellType: user.userType?.productToSellType,
      snsUsageType: user.userType?.snsUsageType,
    };

    // GA에서 사용할 수 있도록 User Scope에 커스텀한 유저정보를 추가해줍니다
    setUserId(this.analytics, user.userId);
    setUserProperties(this.analytics, {
      ...userInfo,
      kemi: user.kemiId,
      link_name: user.linkName,
      title: user.title,
      phoneNumber: user.profile.phoneNumber,
    });

    // amplitude에 전달하기 위해 유저정보를 gtm의 dataLayer에 넣어줍니다
    window?.dataLayer.push({
      user_info: {
        ...userInfo,
        kemi_id: user.kemiId,
        phone_number: user.profile.phoneNumber,
      },
    });

    // setUserProperties이벤트는 앰플리튜드에 유저정보를 세팅하는 이벤트를 트리거합니다
    logEvent(this.analytics, 'setUserProperties');
  }

  init() {
    if (typeof window !== 'undefined') {
      const myApp = initializeApp(this.firebaseConfig);
      this.analytics = analytics.getAnalytics(myApp);
    }
  }
}

export default new FirebaseLogger();
