import { randomCoverImage, randomProfileImage } from '@global/helper/image';
import { MyKemiSettingsQueryResponse } from '@multilink/graphql/Settings.generated';
import { KemiProfileInput, KemiBannerInput } from '@multilink/types';
import { EKemiType } from '@schema/types';
import { isObjectEmpty } from '@utils/collection';

export const LINK_NAME_MAX_LENGTH = 20;
export const BANNER_TITLE_MAX_LENGTH = 10;

export const selectSettingsResponse = (
  data: MyKemiSettingsQueryResponse
): {
  kemiId: string;
  profile: KemiProfileInput;
  banner: KemiBannerInput;
  kemiType: EKemiType;
  productList: { value: string; label: string }[];
} => {
  const settings = data.me?.kemi;

  const productList = (settings?.showingTree || [])
    ?.map((tree) => {
      return settings?.allCards?.find((card) => {
        if (!card || isObjectEmpty(card)) {
          return false;
        }

        return card.id === tree.cardId;
      });
    })
    .flatMap((card) => {
      if (!card || isObjectEmpty(card) || card.__typename !== 'ProductCard') {
        return [];
      }

      return [{ value: card.id, label: card.title.slice(0, 30) }];
    });

  return {
    kemiId: settings?.id || '',
    productList,
    kemiType: settings?.kemiType || 'PROFILE',
    profile: {
      coverImageUrl: settings?.coverImageUrl || randomCoverImage(),
      description: settings?.description || '',
      linkName: settings?.linkName || '',
      profileImageUrl: settings?.profileImageUrl || randomProfileImage(),
      title: settings?.title || '',
    },
    banner: {
      linkName: settings?.linkName || '',
      useBannerTitle: !!settings?.representationImage?.useBannerTitle,
      bannerTitle: settings?.representationImage?.bannerTitle || '',
      autoSlide: !!settings?.representationImage?.autoSlide,
      badge: {
        color: settings?.representationImage?.badge?.color || '',
        text: settings?.representationImage?.badge?.text || '',
        showBadge: !!settings?.representationImage?.badge?.showBadge,
      },
      button: {
        bgColor: settings?.representationImage?.button?.bgColor || '',
        showButton: !!settings?.representationImage?.button?.showButton,
        text: settings?.representationImage?.button?.text || '',
      },
      imageShape:
        settings?.representationImage?.imageShape || 'RECTANGLE_MEDIUM',
      imageUrls: settings?.representationImage?.imageUrls || [],
      link: {
        linkUrl: settings?.representationImage?.link?.linkUrl || '',
        useLink: !!settings?.representationImage?.link?.useLink,
      },
      text: {
        color: settings?.representationImage?.text?.color || '',
        content: settings?.representationImage?.text?.content || '',
        header: settings?.representationImage?.text?.header || '',
        showText: !!settings?.representationImage?.text?.showText,
      },
    },
  };
};

export const kemiTypes: EKemiType[] = ['PROFILE', 'BANNER'];
