import { Field } from 'formik';
import type { FieldProps } from 'formik';
import { get } from 'lodash';

import ErrorMessage from 'src/design0.2/Fields/shared/components/ErrorMessage';
import Input from 'src/design0.2/Inputs/Input';
import { InputProps } from 'src/design0.2/Inputs/shared/types';

function TextField({ name, errorMessage, onRemove, ...props }: InputProps) {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const isTouched = !!name && get(form.touched, name);
        const showError = isTouched && !!get(form.errors, name);

        return (
          <Input
            type={'text'}
            errorMessage={
              errorMessage || (showError ? <ErrorMessage name={name} /> : '')
            }
            showError={showError}
            onRemove={(event) => {
              name && form.setFieldTouched(name, true, true);
              onRemove?.(event);
            }}
            {...field}
            {...props}
          />
        );
      }}
    </Field>
  );
}

export default TextField;
