import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';

import EditLinkName from './EditLinkName';
import { onboardingFormSchema, OnboardingValues } from './validator';

import Button from '@design0.2/Button/Button';
import FullModal from '@design0.2/Modal/FullModal';
import { useModalToast } from '@design0.2/Toast/hooks/useToast';
import { toastUtils } from '@global/components/atoms/ToastContainer/ToastContainer';
import { EVENT_TAG } from '@global/constants';
import useUserQuery from '@global/queries/useUserQuery';
import globalErrorHandler from '@global/service/Error/globalErrorHandler';
import KemiApiError from '@global/service/Error/KemiApiError';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';
import LaterAlert from '@user/components/Onboarding/LaterAlert';
import OnboardingResult from '@user/components/Onboarding/OnboardingResult';
import SelectProductToSellType from '@user/components/Onboarding/SelectProductToSellType';
import SelectSnsUsageType from '@user/components/Onboarding/SelectSnsUsageType';
import {
  useUpdateKemiProfileMutation,
  useUpdateProfileMutation,
} from '@user/graphql/Onboarding.generated';
import { getNextOnboardingStep } from '@user/helpers/onboarding';
import { useOnboardingStatus } from '@user/hooks/useOnboardingStatus';
import { EOnboardingSteps } from '@user/types';
import SelectKemiUsageType from 'src/user/components/Onboarding/SelectKemiUsageType';

type OnboardingModalProps = {
  close: () => void;
  initialStep: EOnboardingSteps;
  isOldOnboardedUser: boolean;
  initialValues: OnboardingValues;
};

function OnboardingModal({
  close,
  initialStep,
  isOldOnboardedUser,
  initialValues,
}: OnboardingModalProps) {
  const { bad } = useModalToast();

  const [step, setStep] = useState<EOnboardingSteps>(initialStep);
  const [linkNameError, setLinkNameError] = useState('');
  const [laterAlertOpen, setLaterAlertOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const { data: user } = useUserQuery();
  const { mutateAsync: mutateKemi } = useUpdateKemiProfileMutation();
  const { mutateAsync: mutateProfile } = useUpdateProfileMutation();

  const { completeOnboarding, updateOnboardingStep } = useOnboardingStatus();

  const kemiId = user?.kemiId;
  const isResultStep = step === 'result';

  const logUpdateSuccessEvent = (
    step: EOnboardingSteps,
    values: OnboardingValues
  ) => {
    switch (step) {
      case 'linkName':
        logFirebase(
          UserInteractionType.CLICK,
          EVENT_TAG.ONBOARDING.ONBOARDING_LINKNAME_NEXT_CLICK,
          { linkName: values.linkName }
        );
        break;
      case 'kemiUsageType':
        logFirebase(
          UserInteractionType.CLICK,
          EVENT_TAG.ONBOARDING.ONBOARDING_USER_TYPE_NEXT_CLICK,
          {
            type: isOldOnboardedUser ? 'old' : 'new',
            userType: values.kemiUsageType,
          }
        );
        break;
      case 'productToSellType':
        logFirebase(
          UserInteractionType.CLICK,
          EVENT_TAG.ONBOARDING.ONBOARDING_SALES_TYPE_NEXT_CLICK,
          {
            type: isOldOnboardedUser ? 'old' : 'new',
            sellerType: values.productToSellType.join(', '),
          }
        );
        break;
      case 'snsUsageType':
        logFirebase(
          UserInteractionType.CLICK,
          EVENT_TAG.ONBOARDING.ONBOARDING_SNS_FREQUENCY_NEXT_CLICK,
          {
            type: isOldOnboardedUser ? 'old' : 'new',
            frequency: values.snsUsageType,
          }
        );
        break;
      default:
        return;
    }
  };

  const updateProfile = (values: OnboardingValues) => {
    return mutateProfile({
      userType: {
        kemiUsageType: values.kemiUsageType || undefined,
        productToSellType: values.productToSellType,
        snsUsageType: values.snsUsageType || undefined,
      },
    });
  };

  const updateStep = async (step: EOnboardingSteps) => {
    await updateOnboardingStep(step);
    setStep(step);
  };

  const handleSubmit = async (
    values: OnboardingValues,
    helpers: FormikHelpers<OnboardingValues>
  ) => {
    if (!kemiId) {
      return;
    }

    const nextStep = getNextOnboardingStep(
      values.kemiUsageType === 'SELLER',
      step
    );

    try {
      if (step === 'linkName') {
        await mutateKemi({
          kemiId,
          /**
           * 온보딩에서는 Linkname이 title이 된다.
           * https://www.notion.so/wiredcompany/df2a3d01cf2045038cbba043208c88a6?pvs=4#9bd14125742d43a79e7fc7f721180a9e
           */
          profile: { linkName: values.linkName, title: values.linkName },
        });

        logUpdateSuccessEvent(step, values);
        await updateStep(nextStep);
        return;
      }

      await updateProfile(values);
      logUpdateSuccessEvent(step, values);
      await updateStep(nextStep);
    } catch (e) {
      if (e instanceof KemiApiError) {
        if (e.code === 'LINKNAME_ALREADY_EXIST') {
          setLinkNameError(
            i18n.t('k_you_cannot_use_the_same_address_as_another_user')
          );
          bad(i18n.t('k_enter_required_field_plz'));
          return;
        }

        if (e.code === 'FORBIDDEN_WORD_INCLUDED') {
          setLinkNameError(toastUtils.invalidWord(e));
          bad(i18n.t('k_enter_required_field_plz'));
          return;
        }
      }

      globalErrorHandler(e);
      setStep(nextStep);
    } finally {
      helpers.resetForm({ values });
    }
  };

  const handleLaterButtonClick = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.ONBOARDING.ONBOARDING_USER_TYPE_LATER_CLICK
    );

    setLaterAlertOpen(true);
  };

  const closeOnboarding = async (completed?: boolean) => {
    try {
      setIsClosing(true);
      await completeOnboarding(completed);
    } catch (e) {
      globalErrorHandler(e);
    } finally {
      setLaterAlertOpen(false);
      close();
      setIsClosing(false);
    }
  };

  useEffect(() => {
    if (step === 'linkName') {
      logFirebase(
        UserInteractionType.PAGE_IN,
        EVENT_TAG.ONBOARDING.SCREEN_OPEN_INBOARDING_LINKNAME
      );
    }

    if (step === 'kemiUsageType') {
      logFirebase(
        UserInteractionType.PAGE_IN,
        EVENT_TAG.ONBOARDING.SCREEN_OPEN_ONBOARDING_USER_TYPE,
        { type: isOldOnboardedUser ? 'old' : 'new' }
      );
    }

    if (step === 'productToSellType') {
      logFirebase(
        UserInteractionType.PAGE_IN,
        EVENT_TAG.ONBOARDING.SCREEN_OPEN_ONBOARDING_SALES_TYPE,
        { type: isOldOnboardedUser ? 'old' : 'new' }
      );
    }

    if (step === 'snsUsageType') {
      logFirebase(
        UserInteractionType.PAGE_IN,
        EVENT_TAG.ONBOARDING.SCREEN_OPEN_ONBOARDING_SNS_FREQUENCY,
        { type: isOldOnboardedUser ? 'old' : 'new' }
      );
    }
  }, [step]);

  return (
    <FullModal disableOutsidePointerEvents withoutHeader>
      <Formik<OnboardingValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={onboardingFormSchema(step)}
      >
        {({ isSubmitting }) => {
          if (isResultStep) {
            return (
              <Form>
                <OnboardingResult
                  isOldOnboardedUser={isOldOnboardedUser}
                  onButtonClick={() => closeOnboarding(true)}
                  isLoading={isClosing}
                />
              </Form>
            );
          }

          return (
            <Form>
              <Header>
                {isOldOnboardedUser && (
                  <HeaderButton
                    type={'button'}
                    onClick={handleLaterButtonClick}
                  >
                    {i18n.t('k_maybe_later')}
                  </HeaderButton>
                )}
              </Header>
              <Content>
                {step === 'linkName' && (
                  <EditLinkName
                    errorMessage={linkNameError}
                    resetLinkNameError={() => setLinkNameError('')}
                  />
                )}
                {step === 'kemiUsageType' && (
                  <SelectKemiUsageType
                    isOldOnboardedUser={isOldOnboardedUser}
                  />
                )}
                {step === 'productToSellType' && <SelectProductToSellType />}
                {step === 'snsUsageType' && <SelectSnsUsageType />}
              </Content>
              <ButtonWrapper>
                <Button type={'submit'} loading={isSubmitting} formNoValidate>
                  {i18n.t('k_next')}
                </Button>
              </ButtonWrapper>
            </Form>
          );
        }}
      </Formik>
      {laterAlertOpen && (
        <LaterAlert
          close={() => setLaterAlertOpen(false)}
          onConfirm={() => {
            logFirebase(
              UserInteractionType.CLICK,
              EVENT_TAG.ONBOARDING.ONBOARDING_USER_TYPE_NEXT_TIME_CLICK
            );
            closeOnboarding();
          }}
          isLoading={isClosing}
        />
      )}
    </FullModal>
  );
}

const Form = styled(FormikForm, {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: '$white',
  overflow: 'scroll',
  paddingX: 16,
});

const Content = styled('div', {
  width: '100%',
  paddingBottom: 48,
});

const Header = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  flex: '0 0 64px',
});

const HeaderButton = styled('button', {
  fontType: 'subtitleBold',
  color: '$grey100',
  cursor: 'pointer',
});

const ButtonWrapper = styled('div', {
  width: '100%',
  paddingY: 24,
  backgroundColor: '$white',
});

export default OnboardingModal;
