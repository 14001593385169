import { Field, useFormikContext } from 'formik';
import { useMemo } from 'react';

import OnboardingTitle from './OnboardingTitle';
import SelectBox from './SelectBox';

import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';
import KemimojiArt from '@user/assets/kemimoji-art.svg';
import KemimojiLaptop from '@user/assets/kemimoji-laptop.svg';
import KemimojiMoney from '@user/assets/kemimoji-money.svg';
import KemimojiShopping from '@user/assets/kemimoji-shopping.svg';
import { OnboardingValues } from '@user/components/Onboarding/validator';
import { useOnboardingErrorToast } from '@user/hooks/useOnboardingErrorToast';

type SelectKemiUsageTypeProps = {
  isOldOnboardedUser: boolean;
};

function SelectKemiUsageType({ isOldOnboardedUser }: SelectKemiUsageTypeProps) {
  const { values } = useFormikContext<OnboardingValues>();

  const kemiUsageType = values.kemiUsageType;

  const { title1, title2, subTitle } = useMemo(() => {
    switch (isOldOnboardedUser) {
      case false:
        return {
          title1: `${i18n.t('k_name_nim', { name: values.linkName })},`,
          title2: i18n.t('k_what_you_want_in_kemi'),
          subTitle: i18n.t('k_change_usage_kemi_by_selection'),
        };
      default:
        return {
          title1: i18n.t('k_tell_me_what_you_are', { name: values.linkName }),
          title2: `${i18n.t('k_provide_fit_benefits')} ☺️`,
          subTitle: i18n.t('k_what_do_you_do_in_kemi'),
        };
    }
  }, [isOldOnboardedUser, values.linkName]);

  useOnboardingErrorToast();

  return (
    <div>
      <OnboardingTitle title1={title1} title2={title2} subTitle={subTitle} />
      <Labels>
        <label>
          <SelectBox
            title={i18n.t('k_user_type_select_seller')}
            emoji={KemimojiMoney}
            active={kemiUsageType === 'SELLER'}
          />
          <Radio type={'radio'} name={'kemiUsageType'} value={'SELLER'} />
        </label>
        <label>
          <SelectBox
            title={i18n.t('k_user_type_select_creator')}
            subTitle={i18n.t('k_user_type_select_creator_sub_title')}
            emoji={KemimojiArt}
            active={kemiUsageType === 'CREATOR'}
          />
          <Radio type={'radio'} name={'kemiUsageType'} value={'CREATOR'} />
        </label>
        <label>
          <SelectBox
            title={i18n.t('k_user_type_select_multilink')}
            emoji={KemimojiLaptop}
            active={kemiUsageType === 'MULTILINK'}
          />
          <Radio type={'radio'} name={'kemiUsageType'} value={'MULTILINK'} />
        </label>
        <label>
          <SelectBox
            title={i18n.t('k_user_type_select_buyer')}
            emoji={KemimojiShopping}
            active={kemiUsageType === 'BUYER'}
          />
          <Radio type={'radio'} name={'kemiUsageType'} value={'BUYER'} />
        </label>
      </Labels>
      <label>
        <SelectEtc active={kemiUsageType === 'ETC'}>
          {i18n.t('k_user_type_select_etc')}
        </SelectEtc>
        <Radio type={'radio'} name={'kemiUsageType'} value={'ETC'} />
      </label>
    </div>
  );
}

const Labels = styled('div', {
  gridRowWithGap: 8,
  paddingY: 64,
});

const Radio = styled(Field, {
  display: 'none',
});

const SelectEtc = styled('p', {
  fontType: 'bodyRegular',
  color: '$grey100',
  cursor: 'pointer',
  padding: 16,
  noTapHighlight: true,

  variants: {
    active: {
      true: {
        fontType: 'bodyBold',
        color: '$secondary100',
      },
    },
  },
});

export default SelectKemiUsageType;
