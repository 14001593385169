import { isNil } from 'lodash';
import Link from 'next/link';
import { forwardRef, HTMLProps, useMemo } from 'react';

import { useGetCountPerCommissionOrderFiltersQuery } from '../graphql/SideMenuDashboard.generated';

import SellerCenterDashboardSkeleton from './SellerCenterDashboardSkeleton';

import Button from '@design0.2/Button/Button';
import Text from '@global/components/atoms/Text';
import { EVENT_TAG } from '@global/constants';
import { User } from '@global/queries/useUserQuery';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import { useGetFacebookAuth } from '@pages/app/hooks/useGetFacebookAuth';
import { useKemiVisitorsSummary } from '@pages/mykemi/analytics/kemi/summary/source/hooks/useKemiVisitorsSummary';
import useInstagramAnalyticsSummary from '@pages/mykemi/analytics/sns/summary/source/hooks/useInstagramAnalyticsSummary';
import ROUTE from '@routers';
import useSellerCenterDashboardData from '@seller-center/shared/hooks/useSellerCenterDashboardData';
import useUserAgent from '@shared/hooks/useUserAgent';
import { styled } from '@styles/stitches.config';
import { getRateOfChange } from '@utils/number';
import { addComma } from '@utils/string';

const formatRate = (rate?: number) => {
  return !isNil(rate)
    ? rate > 0
      ? `+${addComma(Math.floor(rate))}%`
      : `${addComma(Math.floor(rate))}%`
    : '';
};

export const KemiDashboard = () => {
  const { hasFacebookPermission } = useGetFacebookAuth();
  const { todayVisitors, yesterdayVisitors } = useKemiVisitorsSummary();
  const visitorPercentage = getRateOfChange(yesterdayVisitors, todayVisitors);
  const { summaryData } = useInstagramAnalyticsSummary();
  const todayFollowersCount = summaryData?.todayFollowersCount || 0;
  const yesterdayFollowersCount = summaryData?.yesterdayFollowersCount || 0;
  const followerPercentage = getRateOfChange(
    yesterdayFollowersCount,
    todayFollowersCount
  );

  return (
    <>
      <Link href={ROUTE.mykemi.analytics.kemi.index} passHref>
        <Content
          title={i18n.t('k_today_s_kemi_visitor')}
          count={todayVisitors}
          bottom={<GrowSign>{formatRate(visitorPercentage)}</GrowSign>}
          onClick={() => {
            logFirebase(
              UserInteractionType.CLICK,
              EVENT_TAG.KPG_SIDEMENU.KPG_SM_GENERAL_BLOCK_VISITOR_CLICK
            );
          }}
        />
      </Link>
      {hasFacebookPermission && (
        <Link href={ROUTE.mykemi.analytics.sns.index} passHref>
          <Content
            title={i18n.t('k_instagram_followers')}
            count={todayFollowersCount}
            bottom={<GrowSign>{formatRate(followerPercentage)}</GrowSign>}
          />
        </Link>
      )}
    </>
  );
};

type SellerCenterDashboardProps = {
  user: User;
};

export const SellerCenterDashboard = ({ user }: SellerCenterDashboardProps) => {
  const isSeller = user?.sellerInfo?.isSeller;
  // seller 가 아닐 경우 api를 호출하지 않는다.
  // dashboard는 Errorboundary 에 속하지 않으므로 enabled 옵션을 사용해야한다.
  const { isLoading, data } = useSellerCenterDashboardData({
    enabled: !!isSeller,
  });
  const { isDesktop } = useUserAgent();
  const { exchangeUrl, returnUrl } = useMemo(
    () =>
      isDesktop
        ? {
            exchangeUrl: ROUTE.sellerCenter.exchanges,
            returnUrl: ROUTE.sellerCenter.returns,
          }
        : {
            exchangeUrl: ROUTE.sellerCenter.orders,
            returnUrl: ROUTE.sellerCenter.orders,
          },
    [isDesktop]
  );

  if (isLoading) {
    return <SellerCenterDashboardSkeleton isSeller={isSeller} />;
  }

  if (isSeller) {
    return (
      <>
        <Link href={ROUTE.sellerCenter.orders} passHref>
          <Content
            title={`🛎 ${i18n.t('k_new_order')}`}
            count={data.new}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_BLOCK_NEWORDER_CLICK
              );
            }}
          />
        </Link>
        <Link href={ROUTE.sellerCenter.analytics.sales.index} passHref>
          <Content
            title={`💰 ${i18n.t('k_todays_sales')}`}
            count={data.sales.amount}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_BLOCK_NEWORDER_CLICK
              );
            }}
            bottom={
              <Text font={'labelRegular'}>{formatRate(data.sales.growth)}</Text>
            }
          />
        </Link>
        <Link href={exchangeUrl} passHref>
          <Content
            title={'🚨 ' + i18n.t('k_exchange_request_2')}
            count={data.exchange}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_BLOCK_EXCHANGEREQ_CLICK
              );
            }}
          />
        </Link>
        <Link href={returnUrl} passHref>
          <Content
            title={'😭 ' + i18n.t('k_return_request_2')}
            count={data.return}
            onClick={() => {
              logFirebase(
                UserInteractionType.CLICK,
                EVENT_TAG.KPG_SIDEMENU.KPG_SM_SC_BLOCK_REFUNDREQ_CLICK
              );
            }}
          />
        </Link>
      </>
    );
  }

  return (
    <>
      <Link href={exchangeUrl} passHref>
        <Content title={'🚨 ' + i18n.t('k_exchange_request_2')} count={5} />
      </Link>
      <Link href={returnUrl} passHref>
        <Content title={'😭 ' + i18n.t('k_return_request_2')} count={1} />
      </Link>
      <Link href={ROUTE.sellerCenter.analytics.sales.index} passHref>
        <Content
          title={`❤️ ${i18n.t('k_new_order')}`}
          count={381}
          bottom={<GrowSign>{'+25%'}</GrowSign>}
        />
      </Link>
    </>
  );
};

type CommissionsDashboardProps = {
  user: User;
};

export const CommissionDashboard = ({ user }: CommissionsDashboardProps) => {
  const userId = user?.userId || '';
  const { data: commissions } = useGetCountPerCommissionOrderFiltersQuery(
    {
      requestInput: {
        customerId: userId,
      },
      receiveInput: {
        creatorId: userId,
      },
    },
    {
      enabled: !!userId,
    }
  );

  const requestCommissionCount = commissions?.requestCommissions?.filters?.find(
    (filter) => filter?.filter === 'PAYMENT_WAIT'
  )?.count;
  const receiveCommissionCount = commissions?.receiveCommissions?.filters?.find(
    (filter) => filter?.filter === 'REQUESTED'
  )?.count;

  return (
    <>
      <ServiceClosedBanner />
      <Link
        href={`${ROUTE.myCommissions.orders.request.index}?state=PAYMENT_WAIT`}
        as={`${ROUTE.myCommissions.orders.request.index}`}
        passHref
      >
        <Content
          title={'📝 ' + i18n.t('k_requesting_commission')}
          count={requestCommissionCount}
          bottom={
            <CommissionCaptionText>
              {i18n.t('k_waiting_for_the_payment')}
            </CommissionCaptionText>
          }
          onClick={() =>
            logFirebase(
              UserInteractionType.CLICK,
              EVENT_TAG.KPG_SIDEMENU.KPG_SM_CMS_BLOCK_REQUIRING_CLICK
            )
          }
        />
      </Link>
      <Link
        href={`${ROUTE.myCommissions.orders.receive.index}?state=REQUESTED`}
        as={ROUTE.myCommissions.orders.receive.index}
        passHref
      >
        <Content
          title={'💌 ' + i18n.t('k_requested_commission')}
          count={receiveCommissionCount}
          bottom={
            <CommissionCaptionText>
              {i18n.t('k_waiting_for_your_acceptance')}
            </CommissionCaptionText>
          }
          onClick={() =>
            logFirebase(
              UserInteractionType.CLICK,
              EVENT_TAG.KPG_SIDEMENU.KPG_SM_CMS_BLOCK_REQUIRED_CLICK
            )
          }
        />
      </Link>
    </>
  );
};

type ContentProps = {
  title?: string;
  count?: number;
  bottom?: JSX.Element | string;
} & HTMLProps<HTMLAnchorElement>;

const Content = forwardRef<HTMLAnchorElement, ContentProps>(
  ({ href, onClick, title, count, bottom }: ContentProps, ref) => {
    return (
      <ContentContainer href={href} ref={ref} onClick={onClick}>
        <Text font={'captionRegular'} color={'black'} className={'title'}>
          {title}
        </Text>
        <Text font={'bodyBold'} color={'black'} className={'count'}>
          {addComma(count || 0)}
        </Text>
        {bottom}
      </ContentContainer>
    );
  }
);
Content.displayName = 'menu-dashboard-item';

export const DashboardContainer = styled('div', {
  display: 'inline-flex',
  flexWrap: 'wrap',
  margin: '5px',
  verticalAlign: 'top',
  width: '100%',
  gap: 8,
  '& .title': {
    marginBottom: 8,
  },
  '& .count': {
    marginBottom: 4,
  },
});

const ContentContainer = styled('a', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: 16,
  background: '$purple10',
  paddingY: 24,
  cursor: 'pointer',
});

const GrowSign = styled('div', {
  fontType: 'labelRegular',
});
const CommissionCaptionText = styled('div', {
  fontType: 'labelRegular',
});

const SERVICE_CLOSED_NOTICE =
  'https://kemi.oopy.io/31c98284-0ca1-4575-90e8-646ab74a8624';
const WITHDRAWAL_VIEW_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSdxtuVfl80jTgKG11FhEMYaIoM_rNtf_fjHHQ4eN34sk4gA2w/viewform';

function ServiceClosedBanner() {
  return (
    <Banner>
      <div className={'bold'}>
        {
          '케미의 커미션 수익금 출금기능은 2023년 04월 20일부터 이용이 불가합니다.'
        }
      </div>
      <div className={'buttonDescription'}>
        {
          '수익금 출금을 원하시면 아래의 링크로 이동하셔서 필요한 정보를 작성해주세요!'
        }
      </div>
      <a
        className={'notice'}
        href={SERVICE_CLOSED_NOTICE}
        target={'_blank'}
        rel={'noreferrer'}
      >
        <Button>{'커미션 기능 종료 공지사항 📣'}</Button>
      </a>
      <a
        className={'withdrawal'}
        href={WITHDRAWAL_VIEW_FORM}
        target={'_blank'}
        rel={'noreferrer'}
      >
        <Button buttonStyle={'secondary'}>
          {'커미션 수익금 출금신청하기 👉'}
        </Button>
      </a>
    </Banner>
  );
}

const Banner = styled('div', {
  flexBasis: 'auto',
  borderRadius: 16,
  padding: 16,
  backgroundColor: '$red10',
  '& > .bold': {
    fontType: 'subtitleBold',
  },
  '& > .buttonDescription': {
    marginTop: 8,
    fontType: 'subtitleRegular',
  },
  '& > .notice': {
    display: 'block',
    marginTop: 16,
  },
  '& > .withdrawal': {
    display: 'block',
    marginTop: 8,
  },
});
