import { ErrorMessage as FormikErrorMessage } from 'formik';

import { styled } from '@styles/stitches.config';

function ErrorMessage({ name }: { name?: string }) {
  if (!name) {
    return null;
  }

  return <FormikErrorMessage name={name} component={StyledErrorMessage} />;
}

const StyledErrorMessage = styled('div', {
  color: '$red100',
});

export default ErrorMessage;
